import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"70vh"} }
const _hoisted_2 = { class: "text-container" }
const _hoisted_3 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tools_image = _resolveComponent("tools-image")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_tools_image, { class: "tools-image mb-6" })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("maintenance-page.title")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("maintenance-page.maintenance")), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("maintenance-page.sorry")), 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
      ])
    ]),
    _createVNode(_component_SireFooter)
  ], 64))
}