import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "breeding" }
const _hoisted_2 = {
  key: 0,
  class: "breeding__production"
}
const _hoisted_3 = { class: "breeding__production__table" }
const _hoisted_4 = {
  key: 1,
  class: "breeding__general"
}
const _hoisted_5 = { class: "breeding__production__table" }
const _hoisted_6 = {
  key: 2,
  class: "breeding__general"
}
const _hoisted_7 = { class: "breeding__production__table" }
const _hoisted_8 = {
  key: 3,
  class: "breeding__general"
}
const _hoisted_9 = { class: "breeding__production__table" }
const _hoisted_10 = {
  key: 4,
  class: "breeding__general"
}
const _hoisted_11 = { class: "breeding__production__table" }
const _hoisted_12 = {
  key: 5,
  class: "breeding__general"
}
const _hoisted_13 = { class: "exterior" }
const _hoisted_14 = { class: "breeding__production__table" }
const _hoisted_15 = {
  key: 6,
  class: "breeding__general"
}
const _hoisted_16 = { class: "breeding__production__table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_ProductionTable = _resolveComponent("ProductionTable")!
  const _component_BreedingGeneralTable = _resolveComponent("BreedingGeneralTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.lactationInfoList?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.production.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ProductionTable, {
              "body-rows": _ctx.mapProductionBreedingValues(_ctx.lactationInfoList),
              "footer-rows": _ctx.mapFooterRowLactation(_ctx.lactationInfoList),
              "header-rows": _ctx.productionColumns
            }, null, 8, ["body-rows", "footer-rows", "header-rows"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.efficiency.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BreedingGeneralTable, {
              "hide-header": "",
              "is-efficiency": "",
              "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName),
              "header-rows": _ctx.customColumns
            }, null, 8, ["body-rows", "header-rows"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.health.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BreedingGeneralTable, {
              "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
              "header-rows": _ctx.customColumns
            }, null, 8, ["body-rows", "header-rows"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.birth.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BreedingGeneralTable, {
              "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
              "header-rows": _ctx.customColumns
            }, null, 8, ["body-rows", "header-rows"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorabove.header")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_BreedingGeneralTable, {
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList, _ctx.exteriorAboveBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_BreedingGeneralTable, {
                withSubText: "",
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList, _ctx.exteriorBelowBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.functional.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_BreedingGeneralTable, {
              "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
              "header-rows": _ctx.customColumns
            }, null, 8, ["body-rows", "header-rows"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}