import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "clickable",
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width,
    height: _ctx.height,
    viewBox: _ctx.viewBox
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#7F746F",
      "fill-rule": "evenodd",
      d: "M0.765172406,7.37804138 L8.09868463,0.257485373 C8.45224783,-0.0858284576 9.02579754,-0.0858284576 9.37936075,0.257485373 L10.23478,1.08810699 C10.5879658,1.43105442 10.5883432,1.98651202 10.2362893,2.33019224 L4.42419314,7.9998168 L10.235912,13.6698078 C10.5883432,14.013488 10.5875885,14.5689456 10.2344026,14.911893 L9.37898342,15.7425146 C9.02542021,16.0858285 8.4518705,16.0858285 8.09830729,15.7425146 L0.765172406,8.62159222 C0.411609198,8.27827839 0.411609198,7.72135521 0.765172406,7.37804138 Z"
    }, null, -1)
  ]), 8, _hoisted_1))
}