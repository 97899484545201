<style lang="scss" scoped>
@import '../../scss/detail.scss';
</style>

<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="item in headerRows[0].cells" :key="item.id" class="text-left">
                    {{ item.value }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td class="text-left" v-for="cell in  row.cells" :key="cell.id">
                    <span v-if="cell.id === 'additionalValue'" class="custom-cell-additionalValue">{{ formatNumber(cell.value) }}</span>
                    <span v-else :class="{ 'measurement-value': cell.id === 'measurement' }">
                        {{ formatNumber(cell.value) }}
                    </span>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatNumber } from '../../scripts/format-number'
import { translateDaysMixin } from '../../mixins/translate-days';

export default defineComponent({
    name: 'CustomTableView',
    mixins: [translateDaysMixin],
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    },
    methods: {
        formatNumber(value: string) {
            return formatNumber(value)
        }
    }
});
</script>
