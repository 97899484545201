<template>
    <div class="footer">
        <crvLogo />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import crvLogo from '../assets/logo/crvLogo.vue';

export default defineComponent({
    name: "SireFooter",
    components: { crvLogo },
});
</script>

<style lang="scss" scoped>
@import '../scss/footer.scss';
</style>