import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "print-content" }
const _hoisted_2 = { class: "summary__container_desktop" }
const _hoisted_3 = { class: "summary__container__row" }
const _hoisted_4 = { class: "summary__container__block" }
const _hoisted_5 = { class: "summary__container__block flex-column" }
const _hoisted_6 = {
  key: 0,
  class: "custom-table-general"
}
const _hoisted_7 = { class: "summary__container__row" }
const _hoisted_8 = { class: "custom-table-production" }
const _hoisted_9 = { class: "custom-table-birth" }
const _hoisted_10 = { class: "summary__container__row" }
const _hoisted_11 = { class: "summary__container__column" }
const _hoisted_12 = { class: "custom-table-efficiency" }
const _hoisted_13 = { class: "custom-table-health" }
const _hoisted_14 = { class: "summary__container__column" }
const _hoisted_15 = { class: "custom-table" }
const _hoisted_16 = { class: "custom-table-exterior" }
const _hoisted_17 = {
  class: "pleft-for-print",
  style: {"display":"none"}
}
const _hoisted_18 = { class: "summary__container" }
const _hoisted_19 = { class: "summary__container__print" }
const _hoisted_20 = { class: "summary__container__block" }
const _hoisted_21 = { class: "summary__container__block flex-column" }
const _hoisted_22 = { class: "custom-table-general" }
const _hoisted_23 = { class: "summary__container__print" }
const _hoisted_24 = { class: "custom-table-production" }
const _hoisted_25 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_26 = { class: "custom-table-birth" }
const _hoisted_27 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_28 = { class: "summary__container__print" }
const _hoisted_29 = { class: "summary__container__column" }
const _hoisted_30 = { class: "custom-table-efficiency" }
const _hoisted_31 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_32 = { class: "custom-table-health" }
const _hoisted_33 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_34 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_35 = { class: "summary__container__column" }
const _hoisted_36 = { class: "custom-table" }
const _hoisted_37 = { class: "header-styling text-caption font-weight-bold" }
const _hoisted_38 = { class: "custom-table-exterior" }
const _hoisted_39 = { class: "header-styling text-caption font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_ListBlockView = _resolveComponent("ListBlockView")!
  const _component_GeneralTableView = _resolveComponent("GeneralTableView")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_CustomTableView = _resolveComponent("CustomTableView")!
  const _component_BreedingGeneralTable = _resolveComponent("BreedingGeneralTable")!
  const _component_crv_pdf_page = _resolveComponent("crv-pdf-page")!
  const _component_crv_html_to_pdf = _resolveComponent("crv-html-to-pdf")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "px-2",
        variant: "text",
        color: "primary",
        onClick: _ctx.handleClickFoo
      }, {
        default: _withCtx(() => [
          _createVNode(_component_svg_icon, {
            type: "mdi",
            path: _ctx.print
          }, null, 8, ["path"]),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("details.print")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ListBlockView)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.generalBreedingValueGroupList && _ctx.generalBreedingValueGroupList[0] && _ctx.generalBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_GeneralTableView, {
                  "body-rows": _ctx.mapCustomBreedingValues(
                    _ctx.generalBreedingValueGroupList[0].breedingValueList,
                    _ctx.generalBreedingValueGroupList[0].tagName, true
                  )
                  ,
                  "header-rows": _ctx.generalProdColumns
                }, null, 8, ["body-rows", "header-rows"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.production.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_CustomTableView, {
                key: 0,
                "body-rows": _ctx.mapCustomProductionBreedingValues(
                  _ctx.productionBreedingValueGroupList[0].breedingValueList,
                  _ctx.productionBreedingValueGroupList[0].tagName
                )
                ,
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.birth.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.efficiency.header")), 1)
              ]),
              _: 1
            }),
            (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
              ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                  key: 0,
                  hideHeader: "",
                  "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName, true),
                  "header-rows": _ctx.generalColumns
                }, null, 8, ["body-rows", "header-rows"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.health.header")), 1)
              ]),
              _: 1
            }),
            (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
              ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                  key: 0,
                  "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
                  "header-rows": _ctx.generalColumns
                }, null, 8, ["body-rows", "header-rows"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.functional.header")), 1)
              ]),
              _: 1
            }),
            (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
              ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                  key: 0,
                  "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
                  "header-rows": _ctx.generalColumns
                }, null, 8, ["body-rows", "header-rows"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorabove.header")), 1)
              ]),
              _: 1
            }),
            (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
              ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                  key: 0,
                  "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorAboveBreedingValueGroupList[0].tagName
                  )
                  ,
                  "header-rows": _ctx.generalColumns
                }, null, 8, ["body-rows", "header-rows"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1)
              ]),
              _: 1
            }),
            (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
              ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                  key: 0,
                  withSubText: "",
                  "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorBelowBreedingValueGroupList[0].tagName
                  )
                  ,
                  "header-rows": _ctx.generalColumns
                }, null, 8, ["body-rows", "header-rows"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_crv_html_to_pdf, {
        ref: "foo",
        orientation: "portrait",
        name: _ctx.generateFileName(),
        pageNumberLabel: _ctx.getPageName()
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_crv_pdf_page, {
              "is-first-page": "",
              title: _ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : '-',
              subtitle: _ctx.formatEmptyToDash(_ctx.bullDetail.fullName),
              identifier: _ctx.formatEmptyToDash(_ctx.bullDetail.artificialInseminationNumber) + ' | ' + _ctx.formatEmptyToDash(_ctx.bullDetail.lifeNumber),
              "date-label": _ctx.getDate()
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_ListBlockView)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      (_ctx.generalBreedingValueGroupList && _ctx.generalBreedingValueGroupList[0] && _ctx.generalBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                            key: 0,
                            "body-rows": _ctx.mapCustomBreedingValues(
                  _ctx.generalBreedingValueGroupList[0].breedingValueList,
                  _ctx.generalBreedingValueGroupList[0].tagName, true
                )
                  ,
                            "header-rows": _ctx.generalProdColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t("details.production.header")), 1),
                    (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
                      ? (_openBlock(), _createBlock(_component_CustomTableView, {
                          key: 0,
                          "body-rows": _ctx.mapCustomBreedingValues(
                  _ctx.productionBreedingValueGroupList[0].breedingValueList,
                  _ctx.productionBreedingValueGroupList[0].tagName
                )
                ,
                          "header-rows": _ctx.customColumns
                        }, null, 8, ["body-rows", "header-rows"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$t("details.birth.header")), 1),
                    (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
                      ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                          key: 0,
                          "body-rows": _ctx.mapGeneralBreedingValues(_ctx.birthBreedingValueGroupList[0].breedingValueList, _ctx.birthBreedingValueGroupList[0].tagName),
                          "header-rows": _ctx.generalColumns
                        }, null, 8, ["body-rows", "header-rows"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t("details.efficiency.header")), 1),
                      (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                            key: 0,
                            hideHeader: "",
                            "body-rows": _ctx.mapGeneralBreedingValues(_ctx.efficiencyBreedingValueGroupList[0].breedingValueList, _ctx.efficiencyBreedingValueGroupList[0].tagName, true),
                            "header-rows": _ctx.generalColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t("details.health.header")), 1),
                      (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                            key: 0,
                            "body-rows": _ctx.mapGeneralBreedingValues(_ctx.healthBreedingValueGroupList[0].breedingValueList, _ctx.healthBreedingValueGroupList[0].tagName),
                            "header-rows": _ctx.generalColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t("details.functional.header")), 1),
                      (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                            key: 0,
                            "body-rows": _ctx.mapGeneralBreedingValues(_ctx.functionalBreedingValueGroupList[0].breedingValueList, _ctx.functionalBreedingValueGroupList[0].tagName),
                            "header-rows": _ctx.generalColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t("details.exteriorabove.header")), 1),
                      (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                            key: 0,
                            "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorAboveBreedingValueGroupList[0].tagName
                  )
                  ,
                            "header-rows": _ctx.generalColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1),
                      (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
                        ? (_openBlock(), _createBlock(_component_BreedingGeneralTable, {
                            key: 0,
                            withSubText: "",
                            "body-rows": _ctx.mapGeneralBreedingValues(
                    _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
                    _ctx.exteriorBelowBreedingValueGroupList[0].tagName
                  )
                  ,
                            "header-rows": _ctx.generalColumns
                          }, null, 8, ["body-rows", "header-rows"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title", "subtitle", "identifier", "date-label"])
          ])
        ]),
        _: 1
      }, 8, ["name", "pageNumberLabel"])
    ])
  ], 64))
}