<style lang="scss" scoped>
@import '../scss/pedigree.scss';
</style>

<template>
    <v-card elevation="6" class="pa-2">
        <div v-on:click="onClick" v-bind:class=getClickableClass() class="pedigree__card__row">
            <div class="pedigree__card__row__header">
                <div>
                    <div v-if="windowWidth < Device.MOBILEMAX" class="pedigree__type">
                        {{ getTypeText() }}
                    </div>
                    <div class="pedigree__name">
                        {{ formatCardHeaderName(pedigreeBull?.fullName, pedigreeBull?.shortName) }}
                        <template v-if="!isSire">
                            <svg-icon color="#b74d6d" type="mdi" :path="mdiGenderFemale" class="pedigree__name__gender"></svg-icon>
                        </template>
                        <template v-else>
                            <svg-icon color="#2f679c" type="mdi" :path="mdiGenderMale" class="pedigree__name__gender"></svg-icon>
                        </template>
                    </div>

                </div>
                <template v-if="isSire">
                    <chevronRightRegular />
                </template>

            </div>
            <div class="pedigree__card__row__sub">{{ formatCardSubHeaderName(pedigreeBull?.fullName,
                pedigreeBull?.shortName) }}</div> 
        </div>
        <div v-if="type === 'child'" class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.artificialInseminationNumber') }}
            </div>
            <div>{{ formatEmptyToDash(pedigreeBull?.artificialInseminationNumber) }}</div>
        </div>
        <div class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.interbullNumber') }}</div>
            <div>{{ formatEmptyToDash(pedigreeBull?.interbullNumber) }}</div>
        </div>
        <div class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.dateOfBirthSmall') }}</div>
            <div>{{ formatDate(pedigreeBull?.dateOfBirth) }}</div>
        </div>
        <div v-if="type === 'child' || type === 'parents'" class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.raceInfo') }}</div>
            <div>{{ formatEmptyToDash(pedigreeBull?.breed) }}</div>
        </div>
        <div v-if="type === 'child' || type === 'parents'" class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.inheritedFactors') }}</div>
            <div>{{ formatValueOrDash(formatInheritedFactors(pedigreeBull?.inheritedFactors)) }}</div>
        </div>
        <div v-if="type === 'child'" class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.inbreedingCoefficient') }}</div>
            <div> {{ formatInbreedingCoefficient(pedigreeBull?.inbreedingCoefficient) }}</div>
        </div>
        <div v-if="type === 'child'" class="pedigree__card__row">
            <div class="pedigree__card__row__static">{{ $t('details.summary.first.kinshipRate') }}</div>
            <div>{{ formatEmptyToDash(pedigreeBull?.kinshipRate) }}</div>
        </div>

    </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import chevronRightRegular from '../assets/icons/chevronRightRegular.vue'
import { formatValueOrDash } from '../scripts/format-value-or-dash';
import { formatDateMixin } from '../mixins/format-date';
import { Device } from '../models/enums';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiGenderMale, mdiGenderFemale } from '@mdi/js';
import { formatLocale } from "../scripts/format-locale";
import {formatInheritedFactors} from "../scripts/format-inherited-factors";
import {formatInbreedingCoefficient} from "../scripts/format-inbreeding-coefficient";

export default defineComponent({
    name: 'PedigreeCardView',
    mixins: [formatDateMixin],
    props: {
        isSire: {
            type: Boolean,
        },
        type: {
            type: String,
        },
        pedigreeBull: {
            type: Object
        },
        typeParents: {
            type: String
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    emits: ["clicked"],
    data() {
        return {
            windowWidth: 0,
            mdiGenderFemale: mdiGenderFemale,
            mdiGenderMale: mdiGenderMale
        };
    },
    components: {
        chevronRightRegular,
        SvgIcon
    },
    methods: {
      formatValueOrDash,
      formatInbreedingCoefficient,
      formatInheritedFactors,
        FormatLocale(value: string) {
            return formatLocale(value)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        onClick() {
            this.$emit('clicked', this.pedigreeBull?.interbullNumber)
        },
        formatEmptyToDash(value: number | string) {
            return formatValueOrDash(value);
        },
        formatCardHeaderName(fullName: string, shortName: string){
            if (fullName) { 
                return fullName
            }

            else if (shortName) { 
                return shortName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        formatCardSubHeaderName(fullName: string, shortName: string){
            if (shortName) { 
                return shortName
            }
            
            else if (fullName) { 
                return fullName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        getClickableClass() {
            return this.isSire ? "clickable" : "";
        },
        getTypeText(): string {
            switch (this.typeParents) {
                case 'SIRE':
                    return this.$t('pedigree.father')
                case 'DAM':
                    return this.$t('pedigree.mother')
                case 'SS':
                    return this.$t('pedigree.fatherfather')
                case 'SD':
                    return this.$t('pedigree.fathermother')
                case 'DS':
                    return this.$t('pedigree.motherfather')
                case 'DD':
                    return this.$t('pedigree.mothermother')
                case 'SSS':
                    return this.$t('pedigree.vvv')
                case 'SSD':
                    return this.$t('pedigree.mvv')
                case 'SDS':
                    return this.$t('pedigree.vmv')
                case 'SDD':
                    return this.$t('pedigree.mmv')
                case 'DSS':
                    return this.$t('pedigree.vvm')
                case 'DSD':
                    return this.$t('pedigree.mvm')
                case 'DDS':
                    return this.$t('pedigree.vmm')
                case 'DDD':
                    return this.$t('pedigree.mmm')
                default:
                    return ''
            }
        }
    },
    setup() {
        return {
            Device
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>
