import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "documentation" }
const _hoisted_2 = { class: "documentation__formulas" }
const _hoisted_3 = { class: "documentation__formulas" }
const _hoisted_4 = { class: "documentation__container" }
const _hoisted_5 = { class: "documentation__listOne" }
const _hoisted_6 = { class: "list-item text-left" }
const _hoisted_7 = { class: "documentation__listOne__columnTwo" }
const _hoisted_8 = { class: "list-item text-left" }
const _hoisted_9 = { class: "documentation__listOne__columnTwo" }
const _hoisted_10 = { class: "list-item text-left" }
const _hoisted_11 = { class: "documentation__listOne__columnTwo" }
const _hoisted_12 = { class: "list-item text-left" }
const _hoisted_13 = { class: "documentation__listOne__columnTwo" }
const _hoisted_14 = { class: "list-item text-left" }
const _hoisted_15 = { class: "documentation__listOne__columnTwo" }
const _hoisted_16 = { class: "documentation__container" }
const _hoisted_17 = { class: "documentation__listOne" }
const _hoisted_18 = { class: "list-item text-left" }
const _hoisted_19 = { class: "documentation__listOne__columnTwo" }
const _hoisted_20 = { class: "list-item text-left" }
const _hoisted_21 = { class: "documentation__listOne__columnTwo" }
const _hoisted_22 = { class: "list-item text-left" }
const _hoisted_23 = { class: "documentation__listOne__columnTwo" }
const _hoisted_24 = { class: "list-item text-left" }
const _hoisted_25 = { class: "documentation__listOne__columnTwo" }
const _hoisted_26 = { class: "text-left legend mb-6" }
const _hoisted_27 = { class: "documentation__text" }
const _hoisted_28 = { class: "documentation__text" }
const _hoisted_29 = { class: "documentation__text" }
const _hoisted_30 = { class: "documentation__text" }
const _hoisted_31 = { class: "documentation__formulas" }
const _hoisted_32 = { class: "documentation__container" }
const _hoisted_33 = { class: "documentation__listOne" }
const _hoisted_34 = { class: "list-item text-left" }
const _hoisted_35 = { class: "documentation__listOne__columnTwo" }
const _hoisted_36 = { class: "list-item text-left" }
const _hoisted_37 = { class: "documentation__listOne__columnTwo" }
const _hoisted_38 = { class: "list-item text-left" }
const _hoisted_39 = { class: "documentation__listOne__columnTwo" }
const _hoisted_40 = { class: "list-item text-left" }
const _hoisted_41 = { class: "documentation__listOne__columnTwo" }
const _hoisted_42 = { class: "list-item text-left" }
const _hoisted_43 = { class: "documentation__listOne__columnTwo" }
const _hoisted_44 = { class: "list-item text-left" }
const _hoisted_45 = { class: "documentation__listOne__columnTwo" }
const _hoisted_46 = { class: "list-item text-left" }
const _hoisted_47 = { class: "documentation__listOne__columnTwo" }
const _hoisted_48 = { class: "list-item text-left" }
const _hoisted_49 = { class: "documentation__listOne__columnTwo" }
const _hoisted_50 = { class: "list-item text-left" }
const _hoisted_51 = { class: "documentation__listOne__columnTwo" }
const _hoisted_52 = { class: "list-item text-left" }
const _hoisted_53 = { class: "documentation__listOne__columnTwo" }
const _hoisted_54 = { class: "list-item text-left" }
const _hoisted_55 = { class: "documentation__listOne__columnTwo" }
const _hoisted_56 = { class: "documentation__container" }
const _hoisted_57 = { class: "documentation__listOne" }
const _hoisted_58 = { class: "list-item text-left" }
const _hoisted_59 = { class: "documentation__listOne__columnTwo" }
const _hoisted_60 = { class: "list-item text-left" }
const _hoisted_61 = { class: "documentation__listOne__columnTwo" }
const _hoisted_62 = { class: "list-item text-left" }
const _hoisted_63 = { class: "documentation__listOne__columnTwo" }
const _hoisted_64 = { class: "list-item text-left" }
const _hoisted_65 = { class: "documentation__listOne__columnTwo" }
const _hoisted_66 = { class: "list-item text-left" }
const _hoisted_67 = { class: "documentation__listOne__columnTwo" }
const _hoisted_68 = { class: "list-item text-left" }
const _hoisted_69 = { class: "documentation__listOne__columnTwo" }
const _hoisted_70 = { class: "list-item text-left" }
const _hoisted_71 = { class: "documentation__listOne__columnTwo" }
const _hoisted_72 = { class: "list-item text-left" }
const _hoisted_73 = { class: "documentation__listOne__columnTwo" }
const _hoisted_74 = { class: "list-item text-left" }
const _hoisted_75 = { class: "documentation__listOne__columnTwo" }
const _hoisted_76 = { class: "list-item text-left" }
const _hoisted_77 = { class: "documentation__listOne__columnTwo" }
const _hoisted_78 = { class: "list-item text-left" }
const _hoisted_79 = { class: "documentation__listOne__columnTwo" }
const _hoisted_80 = { class: "documentation__formulas" }
const _hoisted_81 = { class: "documentation__container" }
const _hoisted_82 = { class: "text-column" }
const _hoisted_83 = { class: "documentation__text" }
const _hoisted_84 = { class: "documentation__text" }
const _hoisted_85 = { class: "documentation__text" }
const _hoisted_86 = { class: "documentation__text" }
const _hoisted_87 = { class: "documentation__text" }
const _hoisted_88 = { class: "documentation__container" }
const _hoisted_89 = { class: "text-column" }
const _hoisted_90 = { class: "spacing-medium documentation__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("formulas.header")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openChapterLink && _ctx.openChapterLink(...args))),
        class: "link-chapter",
        target: "_blank",
        rel: "noreferrer noopener"
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t("formulas.chapters")) + " ", 1),
        _createVNode(_component_svg_icon, {
          width: "18",
          height: "18",
          color: "#2f679c",
          type: "mdi",
          path: _ctx.mdiOpenInNew
        }, null, 8, ["path"])
      ])
    ]),
    _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("formulas.source.header")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " Nat ", -1)),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("formulas.source.text.nat")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " Int ", -1)),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("formulas.source.text.int")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " Mdn ", -1)),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("formulas.source.text.mdn")), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " OFw ", -1)),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("formulas.source.text.ofw")), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " GNat ", -1)),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("formulas.source.text.gnat")), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " GFw ", -1)),
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("formulas.source.text.gfw")), 1)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " IGFw ", -1)),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("formulas.source.text.igfw")), 1)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " Gint ", -1)),
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("formulas.source.text.gint")), 1)
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " GMdn ", -1)),
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("formulas.source.text.gmdn")), 1)
          ])
        ])
      ])
    ]),
    _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("details.summary.first.inheritedFactors")), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('formulas.inheritedLegend.description')), 1),
      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('formulas.inheritedLegend.plus')), 1),
      _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('formulas.inheritedLegend.minus')), 1),
      _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('formulas.inheritedLegend.asterix')), 1)
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " BD ", -1)),
            _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t("formulas.inherited.bd")), 1)
          ]),
          _createElementVNode("div", _hoisted_36, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " BL ", -1)),
            _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("formulas.inherited.bl")), 1)
          ]),
          _createElementVNode("div", _hoisted_38, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " BR ", -1)),
            _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t("formulas.inherited.br")), 1)
          ]),
          _createElementVNode("div", _hoisted_40, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " BY ", -1)),
            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t("formulas.inherited.by")), 1)
          ]),
          _createElementVNode("div", _hoisted_42, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " CD ", -1)),
            _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.$t("formulas.inherited.cd")), 1)
          ]),
          _createElementVNode("div", _hoisted_44, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " CV ", -1)),
            _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$t("formulas.inherited.cv")), 1)
          ]),
          _createElementVNode("div", _hoisted_46, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " D1 ", -1)),
            _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$t("formulas.inherited.d1")), 1)
          ]),
          _createElementVNode("div", _hoisted_48, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " D2 ", -1)),
            _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t("formulas.inherited.d2")), 1)
          ]),
          _createElementVNode("div", _hoisted_50, [
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " DP ", -1)),
            _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t("formulas.inherited.dp")), 1)
          ]),
          _createElementVNode("div", _hoisted_52, [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " GG ", -1)),
            _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("formulas.inherited.gg")), 1)
          ]),
          _createElementVNode("div", _hoisted_54, [
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " GT ", -1)),
            _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("formulas.inherited.gt")), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_56, [
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("div", _hoisted_58, [
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " LR ", -1)),
            _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("formulas.inherited.lr")), 1)
          ]),
          _createElementVNode("div", _hoisted_60, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " MF ", -1)),
            _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t("formulas.inherited.mf")), 1)
          ]),
          _createElementVNode("div", _hoisted_62, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " MW ", -1)),
            _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("formulas.inherited.mw")), 1)
          ]),
          _createElementVNode("div", _hoisted_64, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " OT ", -1)),
            _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t("formulas.inherited.ot")), 1)
          ]),
          _createElementVNode("div", _hoisted_66, [
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " PO ", -1)),
            _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.$t("formulas.inherited.po")), 1)
          ]),
          _createElementVNode("div", _hoisted_68, [
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " QT ", -1)),
            _createElementVNode("div", _hoisted_69, _toDisplayString(_ctx.$t("formulas.inherited.qt")), 1)
          ]),
          _createElementVNode("div", _hoisted_70, [
            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " RF ", -1)),
            _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("formulas.inherited.rf")), 1)
          ]),
          _createElementVNode("div", _hoisted_72, [
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " RT ", -1)),
            _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.$t("formulas.inherited.rt")), 1)
          ]),
          _createElementVNode("div", _hoisted_74, [
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " TV ", -1)),
            _createElementVNode("div", _hoisted_75, _toDisplayString(_ctx.$t("formulas.inherited.tv")), 1)
          ]),
          _createElementVNode("div", _hoisted_76, [
            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " VR ", -1)),
            _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t("formulas.inherited.vr")), 1)
          ]),
          _createElementVNode("div", _hoisted_78, [
            _cache[31] || (_cache[31] = _createElementVNode("div", { class: "documentation__listOne__columnOne" }, " ZN ", -1)),
            _createElementVNode("div", _hoisted_79, _toDisplayString(_ctx.$t("formulas.inherited.zn")), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_80, [
      _createElementVNode("div", _hoisted_81, [
        _createElementVNode("div", _hoisted_82, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.summary.first.kinshipRate")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-one')), 1),
          _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-two')), 1),
          _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-three')), 1),
          _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-four')), 1),
          _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.$t('formulas.kinshipRate.text-five')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_88, [
        _createElementVNode("div", _hoisted_89, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("formulas.relative.header")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.$t("formulas.relative.text")), 1),
          _createVNode(_component_v_table, { density: "compact" }, {
            default: _withCtx(() => [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: item.id,
                      class: "text-left"
                    }, _toDisplayString(item.value), 1))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: row.id
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
                      return (_openBlock(), _createElementBlock("td", {
                        class: "text-left",
                        key: cell.id
                      }, _toDisplayString(cell.value), 1))
                    }), 128))
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}