<style lang="scss" scoped>
@import "../scss/home.scss";
</style>

<template>            
  <component v-bind:is="getLayout"></component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchView from "./SearchView.vue";
import { mapGetters } from "vuex";
import BullDetailView from "./BullDetailView.vue";
import TranslationView from "@/components/TranslationView.vue";
import MaintenanceView from "@/components/MaintenanceView.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    'search-view': SearchView,
    'bull-detail-view': BullDetailView,
    'translation-view': TranslationView,
    'maintenance-view': MaintenanceView
  },
  computed: {
    ...mapGetters({
      getLayout: "getLayout"
    }),
  },

});
</script>