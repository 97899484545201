import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home__header" }
const _hoisted_3 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_search_input = _resolveComponent("search-input")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_crv_fallback_content = _resolveComponent("crv-fallback-content")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_DisclaimerFooter = _resolveComponent("DisclaimerFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("home.header")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_search_input, {
          class: "mb-4 w-25",
          modelValue: _ctx.searchValue,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
            _ctx.searchBulls
          ],
          placeholder: (_ctx.windowWidth > _ctx.Device.MOBILEMAX) ? _ctx.$t('home.placeholder-text') : _ctx.$t('home.placeholder-text-mobile'),
          hint: _ctx.$t('home.helper-text'),
          "persistent-hint": false,
          "hide-details": false
        }, null, 8, ["modelValue", "placeholder", "hint", "onUpdate:modelValue"]),
        (_ctx.showTable)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 0,
              headers: _ctx.headerRows,
              items: _ctx.bullJson,
              loading: _ctx.isLoading,
              "items-per-page": _ctx.itemsPerPage,
              "items-per-page-options": _ctx.itemsPerPageOptions,
              "item-key": "name",
              "sort-by": _ctx.sortBy,
              "onUpdate:sortBy": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortBy) = $event)),
              "fixed-header": "",
              density: "compact",
              "onClick:row": _ctx.handleClickBodyRowNew
            }, {
              [`item.lifeNumber`]: _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(item.lifeNumber), 1)
              ]),
              [`item.artificialInseminationNumber`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.searchValue],
                  "text-to-highlight": item.artificialInseminationNumber || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.fullName`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.searchValue],
                  "text-to-highlight": item.fullName || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.shortName`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.searchValue],
                  "text-to-highlight": item.shortName || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              [`item.interbullNumber`]: _withCtx(({ item }) => [
                _createVNode(_component_highlight, {
                  "search-words": [_ctx.searchValue],
                  "text-to-highlight": item.interbullNumber || ''
                }, null, 8, ["search-words", "text-to-highlight"])
              ]),
              "no-data": _withCtx(() => [
                _createVNode(_component_crv_fallback_content, {
                  class: "overflow-hidden",
                  "image-name": "noSearchResults",
                  text: _ctx.$t('home.foundNoneText')
                }, null, 8, ["text"])
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page", "items-per-page-options", "sort-by", "onClick:row"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_DisclaimerFooter)
  ], 64))
}