<style lang="scss" scoped>
@import '../scss/pedigree.scss';
</style>

<template>
    <div class="pedigree">
        <div class="pedigree__container">
            <div v-if="windowWidth < Device.MOBILEMAX" class="pedigree__switch">
                <div>
                    <v-btn v-if="getPedigreeState > 0" @click="onClickPreviousPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                            <svg-icon type="mdi" :path="mdiChevronLeft"></svg-icon>
                        </span>{{ getLeftSwitchText() }}
                    </v-btn>
                </div>
                <div>
                    <v-btn v-if="getPedigreeState < 3" @click.prevent="onClickNextPedigree" variant="text" color="#2f679c"
                        type="link"><span class="button-content">
                        </span>{{ getRightSwitchText() }}
                        <svg-icon type="mdi" :path="mdiChevronRight"></svg-icon>
                    </v-btn>
                </div>
            </div>
            <div v-if="(getPedigreeState === 0 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull__root">
                    <PedigreeCardView 
                        :pedigree-bull="getPedigreeBull" 
                        :is-sire="true" 
                        type="child"
                    />
                </div>
            </div>

            <div v-if="(getPedigreeState === 1 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card parentBull">
                <div class="parentBull__sire container">
                    <PedigreeCardView 
                        :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'SIRE' ).identification"
                        :is-sire="true"
                        type="parents"
                        type-parents="SIRE"
                        @clicked="onClickSire"
                    />
                </div>
                <div class="parentBull__cow container">
                    <PedigreeCardView 
                        :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'DAM' ).identification"
                        type="parents"
                        type-parents="DAM"
                        @clicked="onClickSire" 
                    />
                </div>
            </div>
            <div v-if="(getPedigreeState === 2 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRE' ).identification"
                            :is-sire="true"
                            type="parents"
                            type-parents="SS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAM' ).identification"
                            type="parents"
                            type-parents="SD" 
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire grandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRE' ).identification"
                            :is-sire="true" 
                            type="parents"
                            type-parents="DS"
                            @clicked="onClickSire"
                        /> 
                            <!-- TODO fathers only are clickable apparently, shouldt this be visible somehow? -->
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow grandparentsBull">
                        <PedigreeCardView
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAM' ).identification"
                            type="parents"
                            type-parents="DD"
                        />
                    </div>
                </div>
            </div>
            <div v-if="(getPedigreeState === 3 && windowWidth < Device.MOBILEMAX) || windowWidth > Device.MOBILEMAX"
                class="pedigree__card">
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRES_SIRE' ).identification"
                            :is-sire="true" 
                            type-parents="SSS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRES_DAM' ).identification"
                            type-parents="SSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAMS_SIRE' ).identification"
                            :is-sire="true" 
                            type-parents="SDS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAMS_DAM' ).identification"
                            type-parents="SDD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRES_SIRE' ).identification"
                            :is-sire="true"
                            type-parents="DSS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRES_DAM' ).identification"
                            type-parents="DSD"
                        />
                    </div>
                </div>
                <div class="parentBull">
                    <div class="parentBull__sire greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAMS_SIRE' ).identification"
                            :is-sire="true"
                            type-parents="DDS"
                            @clicked="onClickSire"
                        />
                    </div>
                    <div>
                    </div>
                    <div class="parentBull__cow greatGrandparentsBull">
                        <PedigreeCardView 
                            :pedigree-bull="getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAMS_DAM' ).identification"
                            type-parents="DDD"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import PedigreeCardView from '../components/PedigreeCardView.vue'
import { BullService } from "../services/BullService"
import { BullInfo } from "@/models/bullinfo"
import { Device } from '../models/enums'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

const bullService = new BullService();

export default defineComponent({
    name: 'PedigreeView',
    components: {
        PedigreeCardView,
        SvgIcon
    },
    data() {
        return {
            windowWidth: 0,
            bullInfo: {} as BullInfo,
            mdiChevronLeft: mdiChevronLeft,
            mdiChevronRight: mdiChevronRight
        };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    computed: {
        ...mapGetters({
            getPedigreeState: 'getPedigreeState',
            getPedigreeBull: "getPedigreeBull",
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
    },
    methods: {
        ...mapMutations({
            setPedigreeState: "setPedigreeState",
            setPedigreeBull: "setPedigreeBull",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
        }),
        async onClickSire(value: string) {
            const calcType = this.getCalcType;
                const baseColor = this.getBaseColor;
                const interbullNumber = value;
            try {

                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                const pedigree: BullInfo = await bullService.getForPedigreeView(interbullNumber, baseColor, calcType)
                this.setPedigreeBull(pedigree)
                this.setPedigreeState(1)
            }
            catch (error) {
                console.log('helaas geen fokwaarden van gevonden.')
                return
            }
            if (value) {
                this.$emit('clicked', interbullNumber, baseColor, calcType)
            }
        },
        onClickNextPedigree() {
            this.setPedigreeState(this.getPedigreeState + 1)
        },
        onClickPreviousPedigree() {
            this.setPedigreeState(this.getPedigreeState - 1)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getLeftSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.first-generation')
                case 1:
                    return this.$t('pedigree.first-generation')
                case 2:
                    return this.$t('pedigree.second-generation')
                case 3:
                    return this.$t('pedigree.third-generation')
                default:
                    return this.$t('pedigree.first-generation')
            }
        },
        getRightSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.second-generation')
                case 1:
                    return this.$t('pedigree.third-generation')
                case 2:
                    return this.$t('pedigree.fourth-generation')
                case 3:
                    return this.$t('pedigree.fourth-generation')
                default:
                    return this.$t('pedigree.third-generation')
            }
        },
    },
    setup() {
        return {
            Device,
        }
    },
    async mounted() {
        try {
            const pedigree: BullInfo = await bullService.getForPedigreeView(this.bullDetail.primaryKey, this.getBaseColor, this.getCalcType)
            this.setPedigreeBull(pedigree)
        }
        catch (error) {
            return
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>
