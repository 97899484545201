import { AzureSearchService, ISearchService } from "./SearchService";
import { BullInfo } from "../models/bullinfo";
import { FilterExpression, comboBoxValue } from "../models/filterexpression";
import { SearchOptions, odata } from "@azure/search-documents";
import SearchFields from "../scripts/search-fields";
import { BullDetail } from "@/models/enums";

export interface IBullService {
  client: ISearchService;
  getAll(): Promise<BullInfo>;
  getForSearchView(searchText: string): Promise<BullInfo[]>;
  getForDetailView(interbullNumber: string): Promise<BullInfo>;
  getForPedigreeView(interbullNumber: string, baseColor: string, calcType: string): Promise<BullInfo>
  getForBreedingValueView(interbullNumber: string): Promise<BullInfo>;
  THRESHOLD_MAINTENANCE_N_BULLS;
}

const SelectFilterValues = [
  BullDetail.COLOR,
  BullDetail.RACE,
];
const ComboBoxFilterValues = [BullDetail.INHERITED, BullDetail.TRIPLE];
const SOURCE = [  BullDetail.SOURCEEVA,
];

export class BullService implements IBullService {
  client = new AzureSearchService();
  THRESHOLD_MAINTENANCE_N_BULLS = 1;

  getAll(): Promise<BullInfo> {
    const searchOptions = {
      includeTotalCount: true,
      select: SearchFields.searchView,
    } as SearchOptions<never>;

    const results = this.client.getArray(searchOptions, "*");

    return results as Promise<BullInfo>;
  }

  getForSearchView(
    searchText: string,
    filterExpressions?: FilterExpression[]
  ): Promise<BullInfo[]> {
    const searchOptions = {
      includeTotalCount: true,
      select: SearchFields.searchView,
      filter: "calculationType eq 'GENOMIC_BREEDING_VALUE' and ((calculatedBase eq 'Zwartbont' and referenceBase eq 'Zwartbont') or (calculatedBase eq 'Dubbeldoel' and referenceBase eq 'Dubbeldoel') or (calculatedBase eq 'Belgisch blauw' and referenceBase eq 'Belgisch blauw') or (calculatedBase eq 'Roodbont' and referenceBase eq 'Roodbont'))",
      orderBy: ['search.score() desc', `${BullDetail.NVI} desc`],
      queryType: "full",
      searchMode: "all"
    } as SearchOptions<never>;

    this.constructFilterForSearchOptions(filterExpressions, searchOptions);

    const results = this.client.getArray(searchOptions, searchText);

    return results as Promise<BullInfo[]>;
  }

  private constructFilterForSearchOptions(
    filterExpressions: FilterExpression[] | undefined,
    searchOptions: SearchOptions<never>
  ) {
    if (filterExpressions && filterExpressions.length === 0) {
      return;
    }

    let filter = "";
    filterExpressions?.forEach((expression, i) => {
      if (SelectFilterValues.includes(expression.fieldName as BullDetail)) {
        filter += `${expression.fieldName} eq _${expression.value}_`;
      }

      if (expression.fieldName === BullDetail.INHERITED) {
        const comboBoxArray = JSON.parse(
          JSON.stringify(
            (expression.value as comboBoxValue[]).map((value) => value.id)
          )
        );
        comboBoxArray.forEach((ex, i) => {
          filter += `search.ismatchscoring(_${ex}_, _${BullDetail.INHERITED}_)`;
          comboBoxArray.length - 1 != i ? (filter += " and ") : null;
        });
      }

      if (expression.fieldName === BullDetail.TRIPLE) {
        const comboBoxArray = JSON.parse(
          JSON.stringify(
            (expression.value as comboBoxValue[]).map((value) => value.id)
          )
        );

        comboBoxArray.forEach((ex, i) => {
          filter += `${BullDetail.TRIPLE} eq ${ex}`;
          comboBoxArray.length - 1 != i ? (filter += " and ") : null;
        });
      }

      if (expression.fieldName === BullDetail.SOURCEEVA) {
        filter += `search.ismatchscoring(_${expression.value}_, _${BullDetail.SOURCEEVA}_)`;

      }

      if (
        !SelectFilterValues.includes(expression.fieldName as BullDetail) &&
        !ComboBoxFilterValues.includes(expression.fieldName as BullDetail) &&
        !SOURCE.includes(expression.fieldName as BullDetail)
      ) {
        filter += `(${expression.fieldName} ge ${expression.minNumber} and ${expression.fieldName} le ${expression.maxNumber})`;
      }

      filterExpressions.length - 1 != i ? (filter += " and ") : null;
    });

    searchOptions.filter = odata`${filter}`
      .replace(/'/g, "")
      .replace(/_/g, "'") + ' and ' + searchOptions.filter 
  }

  getForDetailView(interbullNumber: string): Promise<BullInfo> {
    const searchOptions = {
      includeTotalCount: true,
      select: SearchFields.detailView,
    } as SearchOptions<never>;

    const results = this.client.getSingle(interbullNumber);

    return results as Promise<BullInfo>;
  }

  async getForPedigreeView(interbullNumber: string, baseColor: string, calcType: string): Promise<BullInfo> {
    const searchOptions = {
      includeTotalCount: true,
      select: SearchFields.pedigreeView,
    } as SearchOptions<never>;

    const results = this.client.getSingle(interbullNumber);

    return results as Promise<BullInfo>;
  }

  getForBreedingValueView(interbullNumber: string): Promise<BullInfo> {
    const searchOptions = {
      includeTotalCount: true,
      select: SearchFields.breedingValuesView,
    } as SearchOptions<never>;

    const results = this.client.getSingle(interbullNumber);

    return results as Promise<BullInfo>;
  }

  async isUnderMaintenance(): Promise<boolean>{
    try {
      const searchOptions = {
        includeTotalCount: true,
        select: SearchFields.searchView,
      } as SearchOptions<never>;
      const arr = await this.client.getArray(searchOptions, "");
      return (arr as Array<any>).length < this.THRESHOLD_MAINTENANCE_N_BULLS;
    }
    catch (error){
      return true;
    }
  }
}
