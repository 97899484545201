<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from "vuex";
import {BullService} from "../services/BullService";
import {formatDateMixin} from "../mixins/format-date";
import {BullInfo} from "@/models/bullinfo";
import {Device} from "@/models/enums";
import {FilterExpression} from "@/models/filterexpression";
import {mdiFilter} from '@mdi/js';
import {formatNumberMixin} from '../mixins/format-number'
import DisclaimerFooter from "@/components/DisclaimerFooter.vue"
import SearchInput from "@/components/SearchInput.vue"
import highlight from 'vue-highlight-words'
import { CrvFallbackContent } from '@crv/vue-3-components'

export const bullService = new BullService();
const bullDetail: any = {}
const filterValues: FilterExpression[] = []

export default defineComponent({
  name: "SearchView",
  mixins: [formatDateMixin, formatNumberMixin],
  components: {
    DisclaimerFooter,
    SearchInput,
    CrvFallbackContent,
    highlight
  },
  data() {
    return {
      dialog: false,
      path: mdiFilter,
      filteredValues: filterValues,
      windowWidth: 0,
      search: "",
      searchValue: '',
      isLoading: false,
      bullJson: [] as BullInfo[],
      bullDetail: bullDetail,
      sortBy: [{ key: 'dutchFlemishIndex', order: 'dsc' }], // NVI
      itemsPerPage: 25,
      itemsPerPageOptions: [
        {value: 10, title: '10'},
        {value: 25, title: '25'},
        {value: 50, title: '50'},
        {value: 100, title: '100'},
        // {value: -1, title: 'Show all'} // Available when Api returns more than 100
      ],
    }
  },



  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapGetters({
      getBulls: "getBulls",
      getSearchText: "getSearchText",
      getFilteredValues: "getFilteredValues"
    }),

    showTable() {
      if (this.searchValue && this.searchValue.length >= 3) {
         return true
        }
        return false
    },

    headerRows(): { value: string, title: string, sortable: boolean, order?: string }[] {
      return [
        {
          value: 'lifeNumber', // hidden from view but searchable
          title: '',
          sortable: false,
        },
        {
          value: 'artificialInseminationNumber',
          title: this.$t('home.table-headers.ai'),
          sortable: true
        },
        {
          value: 'fullName',
          title: this.$t('home.table-headers.fullName'),
          sortable: true
        },
        {
          value: 'shortName',
          title: this.$t('home.table-headers.shortName'),
          sortable: true
        },
        {
          value: 'interbullNumber',
          title: this.$t('home.table-headers.interbullNumber'),
          sortable: true
        },
        {
          value: 'dateOfBirth',
          title: this.$t('home.table-headers.dateOfBirth'),
          sortable: true
        },
        {
          value: 'dutchFlemishIndex', // NVI
          title: this.$t('home.table-headers.nvi'),
          sortable: true,
          order: "desc",
        },
        {
          value: 'inet',
          title: this.$t('home.table-headers.inet'),
          sortable: true
        },
        {
          value: 'milkKilograms',
          title: this.$t('home.table-headers.milkKilograms'),
          sortable: true
        },
        {
          value: 'percentageFat',
          title: this.$t('home.table-headers.percentageFat'),
          sortable: true
        },
        {
          value: 'percentageProtein',
          title: this.$t('home.table-headers.percentageProtein'),
          sortable: true
        },
        {
          value: 'foodCostSaving',
          title: this.$t('home.table-headers.foodCostSaving'),
          sortable: true
        },
        {
          value: 'lifeSpan',
          title: this.$t('home.table-headers.lifeSpan'),
          sortable: true
        },
        {
          value: 'udderHealth',
          title: this.$t('home.table-headers.udderHealth'),
          sortable: true
        },
        {
          value: 'fertility',
          title: this.$t('home.table-headers.fertility'),
          sortable: true
        },
        {
          value: 'clawHealth',
          title: this.$t('home.table-headers.clawHealth'),
          sortable: true
        },
        {
          value: 'birthIndex',
          title: this.$t('home.table-headers.birthIndex'),
          sortable: true
        },
        {
          value: 'birthEase',
          title: this.$t('home.table-headers.birthEase'),
          sortable: true
        },
        {
          value: 'totalExterior',
          title: this.$t('home.table-headers.totalExterior'),
          sortable: true
        },
        {
          value: 'udder',
          title: this.$t('home.table-headers.udder'),
          sortable: true
        },
        {
          value: 'legwork',
          title: this.$t('home.table-headers.legwork'),
          sortable: true
        }
      ]
    },
  },

  watch: {
    searchValue(newValue) {
      if (newValue && newValue.length >= 3) {
        this.searchBulls(newValue)
      } else {
        this.bullJson = []
      }
    },
  },
  

  methods: {
    ...mapMutations({
      setBulls: "setBulls",
      setBullDetail: "setBullDetail",
      setLayout: "setLayout",
      setSearchText: "setSearchText",
      setFilteredValues: "setFilteredValues",
      setCalcType: "setCalcType",
      setBaseColor: "setBaseColor"
    }),

    async searchBullsBy(value: string) {
      this.search = value;
      this.setSearchText(this.search);

      if (this.filteredValues.length < 1) {
        if (value.length == 0) {
          this.setState([])
        }
        if (value.length < 3 || !value) {
          return
        }
      }

      this.isLoading = true;
      try {
        const result = await bullService.getForSearchView(value, this.getFilteredValues)


        if (result) {
          this.isLoading = false
        }
        this.bullJson = result.length === 0 ? [] : result;


      } catch (error) {
        this.isLoading = false
      }

      this.setState(this.bullJson);
    },


    async searchBulls(searchTerm) {
      if(searchTerm && searchTerm.length >= 3) {
        this.isLoading = true;
      try {
        const result = await bullService.getForSearchView(searchTerm, this.getAllFilteredValues())
        this.bullJson = result.length === 0 ? [] : result;
        } catch (error) {
          console.error('API Error:', error)
        } finally {
          this.setState(this.bullJson);
          this.isLoading = false;
        }
      }
    },

    getAllFilteredValues() {
      return this.getFilteredValues ? this.getFilteredValues : []
    },

    async handleClickBodyRowNew(evt: PointerEvent, payload: any) {
      const bull = payload?.internalItem?.raw?.primaryKey || null // return null when nada?
      const result: BullInfo = await bullService.getForDetailView(bull)
      this.bullDetail = result

      this.setBullDetail(this.bullDetail);
      this.setBaseColor(this.bullDetail.referenceBase)
      if (this.bullDetail) {
        this.setLayout('bull-detail-view')
      }
    },


    /**
     * Fills the state with last queried bulls (bullJson)
     * Call this function after every query so that the state will always be up-to-date.
     */
    setState(state: BullInfo[]) {
      this.setBulls(state);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    // triggerSearchByUrlParameters(searchTermUrlParameterValue: string){
    //   const searchInputField = (document.querySelectorAll('*[id^="crv-input"]')[0] as HTMLInputElement);

    //   searchInputField.value = searchTermUrlParameterValue;
    //   searchInputField.dispatchEvent(new Event("input"));
    // },

    // triggerLocaleChangeByUrlParameter(localeUrlParameterValue: string){
    //   switch (localeUrlParameterValue) {
    //     case Locales.nlNl:
    //       this.$i18n.locale = localeUrlParameterValue;
    //       break;
    //     case Locales.enUs:
    //       this.$i18n.locale = localeUrlParameterValue;
    //       break;
    //     default:
    //       this.$i18n.locale = Locales.nlNl;
    //   }
    // }
  },
  setup() {
    return {
      Device
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
});
</script>


<template>
  <div class="home">
    <div class="home__header">
      <v-card-title class="crv-header crv-header level-two">
        <!-- TODO: apply H2 -->
        {{ $t("home.header") }}
      </v-card-title>
    </div>

    <div>
      <search-input
        class="mb-4 w-25"
        v-model="searchValue"
        :placeholder="(windowWidth > Device.MOBILEMAX) ? $t('home.placeholder-text') : $t('home.placeholder-text-mobile')"
        :hint="$t('home.helper-text')"
        :persistent-hint="false"
        :hide-details="false"
        @update:model-value="searchBulls"
      />
      <v-data-table
        v-if="showTable"
        :headers="headerRows"
        :items="bullJson"
        :loading="isLoading"
        :items-per-page="itemsPerPage"
        :items-per-page-options="itemsPerPageOptions"
        item-key="name"
        v-model:sort-by="sortBy"
        fixed-header
        density="compact"
        @click:row="handleClickBodyRowNew"
      >
        <template #[`item.lifeNumber`]="{ item }">
          <span style="display: none">{{ item.lifeNumber }}</span>
          <!-- searchable but not visible -->
        </template>
        <template #[`item.artificialInseminationNumber`]="{ item }">
            <highlight
                :search-words="[searchValue]"
                :text-to-highlight="item.artificialInseminationNumber || ''"
            />
        </template>
        <template #[`item.fullName`]="{ item }">
            <highlight
                :search-words="[searchValue]"
                :text-to-highlight="item.fullName || ''"
            />
        </template>
        <template #[`item.shortName`]="{ item }">
            <highlight
                :search-words="[searchValue]"
                :text-to-highlight="item.shortName || ''"
            />
        </template>
        <template #[`item.interbullNumber`]="{ item }">
            <highlight
                :search-words="[searchValue]"
                :text-to-highlight="item.interbullNumber || ''"
            />
        </template>
        <template #no-data>
          <crv-fallback-content
              class="overflow-hidden"
              image-name="noSearchResults"
              :text="$t('home.foundNoneText')"
          />
        </template>
      </v-data-table>
    </div>
  </div>
  <DisclaimerFooter/>
</template>


<style lang="scss" scoped>
@import "../scss/home.scss";

</style>