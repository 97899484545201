
<template>
  <div class="genomics-summary__container">
    <div class="summary__container__row">
      <div class="summary__container__alert">
        <v-alert type="info" variant="tonal">
          <template v-slot:title>
            <div class="summary__container__alert__title">
              {{ $t('details.genomics-alert-header') }}

            </div>
          </template>
          <div class="summary__container__alert__text">{{ $t('details.genomics-alert-text-one') }}</div>
          <div class="summary__container__alert__text">{{ $t('details.genomics-alert-text-two') }}</div>
          <div class="summary__container__alert__text">{{ $t('details.genomics-alert-text-three') }}</div>
          <div class="summary__container__alert__text">{{ $t('details.genomics-alert-text-four') }}</div>
          <div class="summary__container__alert__text">{{ $t('details.genomics-alert-text-five') }}</div>
        </v-alert>
      </div>
    </div>
    <div class="summary__container__row">
      <div class="custom-table-production">
        <v-card-title class="crv-header crv-header level-two">
          {{ $t("details.production.header")
          }}
        </v-card-title>
        <CustomTableView
          v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapCustomProductionBreedingValues(
            productionBreedingValueGroupList[0].breedingValueList,
            productionBreedingValueGroupList[0].tagName
          )
          " :header-rows="customColumns" />
      </div>
      <div class="custom-table-general">
          <GeneralTableView 
            v-if="generalBreedingValueGroupList && generalBreedingValueGroupList[0] && generalBreedingValueGroupList[0].breedingValueList.length > 0"
            :body-rows="mapGeneralBreedingValues(
              generalBreedingValueGroupList[0].breedingValueList,
              generalBreedingValueGroupList[0].tagName
            )
            " 
            :header-rows="generalColumns" />
      </div>
    </div>

    <div class="summary__container__row">
      <div class="summary__container__column">
        <div class="custom-table-efficiency">
          <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.efficiency.header")
            }}
          </v-card-title>
          <CustomTableView 
            v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
            :body-rows="mapCustomBreedingValues(
              efficiencyBreedingValueGroupList[0].breedingValueList,
              efficiencyBreedingValueGroupList[0].tagName
            )
            " :header-rows="customColumns" />
        </div>
        <div class="custom-table-health">
          <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.health.header") }}
          </v-card-title>
          <GeneralTableView
            v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
            :body-rows="mapGeneralBreedingValues(
              healthBreedingValueGroupList[0].breedingValueList,
              healthBreedingValueGroupList[0].tagName
            )
            " :header-rows="generalColumns" />
        </div>
        <div>
          <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.functional.header")
            }}
          </v-card-title>
          <GeneralTableView
            v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
            :body-rows="mapGeneralBreedingValues(
              functionalBreedingValueGroupList[0].breedingValueList,
              functionalBreedingValueGroupList[0].tagName
            )
            " :header-rows="generalColumns" />
        </div>
      </div>
      <div class="summary__container__column">
        <div class="custom-table-birth">
        <v-card-title class="crv-header crv-header level-two">
          {{ $t("details.birth.header")
          }}
        </v-card-title>
        <GeneralTableView
          v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
          :body-rows="mapGeneralBreedingValues(
            birthBreedingValueGroupList[0].breedingValueList,
            birthBreedingValueGroupList[0].tagName
          )
          " :header-rows="generalColumns" />
      </div>
        <div class="custom-table">
          <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.exteriorabove.header")
            }}
          </v-card-title>
          <GeneralTableView
            v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0" 
            :body-rows="mapGeneralBreedingValues(
              exteriorAboveBreedingValueGroupList[0].breedingValueList,
              exteriorAboveBreedingValueGroupList[0].tagName
            )
            " :header-rows="generalColumns" />
        </div>
        <div class="custom-table-exterior">
          <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.exteriorbelow.header")
            }}
          </v-card-title>
          <GeneralTableView
            v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0" 
            :body-rows="mapGeneralBreedingValues(
              exteriorBelowBreedingValueGroupList[0].breedingValueList,
              exteriorBelowBreedingValueGroupList[0].tagName
            )
            " :header-rows="generalColumns" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatValueOrDash } from "../scripts/format-value-or-dash";
import { formatGenomicValue } from "../scripts/format-genomic-value";
import { mapGetters, useStore } from "vuex";
import { computed, ComputedRef } from "vue";
import { BreedingValue, BreedingValueSubValue } from "../models/bullinfo";
import GeneralTableView from "./BlockView/GeneralTableView.vue";
import CustomTableView from "./BlockView/CustomTableView.vue";
import { TableName, HeaderValues } from "../models/enums";
import { BreedingValueGroup } from "../models/bullinfo";
import { GeneralProdColumns, CustomColumns } from "../models/table-headers";
import { formatNumber } from '@/scripts/format-number';

export default defineComponent({
  name: "GenomicsView",

  data() {
    return {
      generalColumns: [
        {
          id: "general-column",
          cells: GeneralProdColumns
        },
      ],
      customColumns: [
        {
          id: "production-column",
          cells: CustomColumns
        },
      ],
    };
  },
  setup() {
    const store = useStore();

    const generalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.GENERAL);
      });
    const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.HEALTH);
      });
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EFFICIENCY);
      });
    const productionBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.PRODUCTION);
      });


    return {
      generalBreedingValueGroupList,
      healthBreedingValueGroupList,
      functionalBreedingValueGroupList,
      birthBreedingValueGroupList,
      exteriorAboveBreedingValueGroupList,
      exteriorBelowBreedingValueGroupList,
      efficiencyBreedingValueGroupList,
      productionBreedingValueGroupList,
    };
  },
  components: {
    GeneralTableView,
    CustomTableView
  },
  computed: {
    ...mapGetters({
      bullDetail: "getBullDetail",
    }),
  },
  methods: {
    mapCustomProductionBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`)
            },
            {
              id: 'value',
              value: value.value == 0 ? 0 : formatValueOrDash(value.value)
            },
            {
              id: '%btbh',
              value: value.reliabilityPercentage == 0 ? 0 : formatValueOrDash(value.reliabilityPercentage)
            },
          ],
        }));
    },
    formatEmptyToDash(value: number | string | undefined): number | string | undefined {
      return formatValueOrDash(value);
    },
    formatGenomicValue(value: number | undefined): number | string {
      return formatGenomicValue(value);
    },
    mapCustomBreedingValues(list: BreedingValue[], tagName: string) {
      let mappedBreedingValues;
      // eslint-disable-next-line
        mappedBreedingValues = list.map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
            },
            {
              id: HeaderValues.VALUE,
              value: value.value === undefined || value.value === null ? '-' : formatNumber(String(value.value))
            },
            {
              id: HeaderValues.BTBH,
              value: value.reliabilityPercentage == 0 ? 0 : formatValueOrDash(value.reliabilityPercentage)
            },
          ],
        }))

      return mappedBreedingValues;
    },

    mapGeneralBreedingValues(list: BreedingValue[], tagName: string) {
      const breedingValueArray: BreedingValue[] = [];
      for (let index = 0; index < list.length; index++) {
        const element: BreedingValue = list[index];
        breedingValueArray.push(element);

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          const subValueList = element.breedingValueSubValueList;
          if (subValueList === undefined){
            //
          }
          else{
            const birthEase = subValueList.filter(
              (subvalue: BreedingValueSubValue) =>
                subvalue.tagName === "BirthEase"
            )[0];
            // birthEase.showOnDetailView = true;
            breedingValueArray.push(birthEase as BreedingValue);
          }
        }
      }

      const alist = breedingValueArray
        .map((value: BreedingValue) => {
          if (!value || !value.tagName) {
            return undefined
          }

          return {
            id: value.tagName,
            cells: [
              {
                id: value.tagName,
                value: this.$t(`details.${value.tagName.toLowerCase()}.${value.tagName}`),
              },
              {
                id: HeaderValues.VALUE,
                value: value.value === 0 ? 0 : formatValueOrDash(value.value),
              },
              {
                id: HeaderValues.BTBH,
                value: value.reliabilityPercentage === 0
                  ? 0
                  : formatValueOrDash(value.reliabilityPercentage),
              },
            ],
          };
        })
        .filter(item => item !== undefined)

      return alist
    },
  },
});
</script>
