// TODO: Hard-copy from crv-sireMatch, normally imported from within vite.config.ts - since this project is not a vite project yet, accept hard-copy untill converted?

// Vuetify styles (like .text-h1) were not imported using import 'vuetify/styles' (from the docs)
import 'vuetify/lib/styles/main.css' 

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// import { CrvTheme } from '@crv/vue-3-components'
// TODO: Get theming from vue-3-components
// Also remove the base-colors-copy.modules.scss and crvThemeCopy.js when done.


import CrvTheme from "./crvThemeCopy"
import { mdi, aliases } from 'vuetify/iconsets/mdi-svg'
import { mdiCheck, mdiDelete, mdiDownload, mdiSync, mdiMagnify, mdiWifi, mdiWifiAlert, mdiChevronRight, mdiFilePdfBox, mdiArrowULeftTop, mdiPencil,
    mdiArrowUpThin, mdiArrowDownThin, mdiArrowLeftThin, mdiChat, mdiArchive, mdiHelpCircle, mdiCog, mdiPrinter, mdiCalculator, mdiAccountGroup,  mdiWarehouse,
    mdiRadar, mdiHandHeart, mdiFormatListBulletedType, mdiFlagCheckered, mdiMinus } from '@mdi/js'
import * as labsComponents from 'vuetify/labs/components'


// * we are not importing vuetify/iconsets/mdi because this increases the build size with 3MB (all mdi icons)
// * instead we only import vuetify internal icons from vuetify/iconsets/mdi-svg
// * for a list of these icons see https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/iconsets/mdi-svg.ts
// * 
// * for mdi icons used in Sirematch but not provided by vuetify the procedure is as follows:
// *
// * 1. import the icon from @mdi/js (is treeshakeable):
// *    import { mdiCheck } from '@mdi/js'
// *
// * 2. define an alias for the icon:
// *    aliases: {
// *        ...aliases,
// *        check: mdiCheck,
// *    },
// *
// * Example usage of vuetify or custom icons: <v-icon :icon="enums.Icon.CHECK" />


export const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },   
    directives,
    theme: {
        defaultTheme: 'CrvTheme',
        themes: {
            CrvTheme,
        }
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
            check: mdiCheck,
            delete: mdiDelete,
            download: mdiDownload,
            sync: mdiSync,
            magnify: mdiMagnify,
            wifi: mdiWifi,
            wifiAlert: mdiWifiAlert,
            chevronRight: mdiChevronRight,
            pdf: mdiFilePdfBox,
            undo: mdiArrowULeftTop,
            edit: mdiPencil,
            sortAsc: mdiArrowUpThin, // Overwrite vuetify default
            sortDesc: mdiArrowDownThin, // Overwrite vuetify default
            chat: mdiChat,
            archive: mdiArchive,
            help: mdiHelpCircle,
            cog: mdiCog,
            printer: mdiPrinter,
            calculator: mdiCalculator,
            assortment: mdiWarehouse,
            customers: mdiAccountGroup,
            arrowLeft: mdiArrowLeftThin,
            radar: mdiRadar,
            handHeart: mdiHandHeart,
            formatListBulletedType: mdiFormatListBulletedType,
            flagCheckered: mdiFlagCheckered,
            minus: mdiMinus,
        },
        sets: {
            mdi,
        }
    },
})
