import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_select, {
      "single-line": "",
      class: "header__select",
      modelValue: _ctx.$i18n.locale,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
      "item-value": "param",
      "item-text": "title",
      items: _ctx.LayoutLanguages,
      variant: "outlined"
    }, null, 8, ["modelValue", "items"])
  ]))
}