<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox">
        <path fill="#7F746F" fill-rule="evenodd"
            d="M1.32863637,0.127010724 L0.624916572,0.842339166 C0.458361143,1.01165067 0.458361143,1.28617665 0.624916572,1.45552429 L7.04753705,8 L0.624916572,14.5444667 C0.458361143,14.7137782 0.458361143,14.9883042 0.624916572,15.1576518 L1.32863637,15.8730164 C1.4951918,16.0423279 1.76524902,16.0423279 1.93183999,15.8730164 L9.37508343,8.30660611 C9.54163886,8.13729461 9.54163886,7.86276862 9.37508343,7.69342099 L1.93183999,0.127010724 C1.76524902,-0.0423369079 1.4951918,-0.0423369079 1.32863637,0.127010724 Z" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "chevronRightRegular",
    props: {
        width: {
            type: Number,
            default: 10
        },
        height: {
            type: Number,
            default: 16
        }
    },
    computed: {
        viewBox(): string {
            return '0 0 ' + this.width + ' ' + this.height
        }
    }

});
</script>