import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pedigree" }
const _hoisted_2 = { class: "pedigree__container" }
const _hoisted_3 = {
  key: 0,
  class: "pedigree__switch"
}
const _hoisted_4 = { class: "button-content" }
const _hoisted_5 = {
  key: 1,
  class: "pedigree__card"
}
const _hoisted_6 = { class: "parentBull__root" }
const _hoisted_7 = {
  key: 2,
  class: "pedigree__card parentBull"
}
const _hoisted_8 = { class: "parentBull__sire container" }
const _hoisted_9 = { class: "parentBull__cow container" }
const _hoisted_10 = {
  key: 3,
  class: "pedigree__card"
}
const _hoisted_11 = { class: "parentBull" }
const _hoisted_12 = { class: "parentBull__sire grandparentsBull" }
const _hoisted_13 = { class: "parentBull__cow grandparentsBull" }
const _hoisted_14 = { class: "parentBull" }
const _hoisted_15 = { class: "parentBull__sire grandparentsBull" }
const _hoisted_16 = { class: "parentBull__cow grandparentsBull" }
const _hoisted_17 = {
  key: 4,
  class: "pedigree__card"
}
const _hoisted_18 = { class: "parentBull" }
const _hoisted_19 = { class: "parentBull__sire greatGrandparentsBull" }
const _hoisted_20 = { class: "parentBull__cow greatGrandparentsBull" }
const _hoisted_21 = { class: "parentBull" }
const _hoisted_22 = { class: "parentBull__sire greatGrandparentsBull" }
const _hoisted_23 = { class: "parentBull__cow greatGrandparentsBull" }
const _hoisted_24 = { class: "parentBull" }
const _hoisted_25 = { class: "parentBull__sire greatGrandparentsBull" }
const _hoisted_26 = { class: "parentBull__cow greatGrandparentsBull" }
const _hoisted_27 = { class: "parentBull" }
const _hoisted_28 = { class: "parentBull__sire greatGrandparentsBull" }
const _hoisted_29 = { class: "parentBull__cow greatGrandparentsBull" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_PedigreeCardView = _resolveComponent("PedigreeCardView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.windowWidth < _ctx.Device.MOBILEMAX)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              (_ctx.getPedigreeState > 0)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.onClickPreviousPedigree,
                    variant: "text",
                    color: "#2f679c",
                    type: "link"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, [
                        _createVNode(_component_svg_icon, {
                          type: "mdi",
                          path: _ctx.mdiChevronLeft
                        }, null, 8, ["path"])
                      ]),
                      _createTextVNode(_toDisplayString(_ctx.getLeftSwitchText()), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              (_ctx.getPedigreeState < 3)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _withModifiers(_ctx.onClickNextPedigree, ["prevent"]),
                    variant: "text",
                    color: "#2f679c",
                    type: "link"
                  }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "button-content" }, null, -1)),
                      _createTextVNode(_toDisplayString(_ctx.getRightSwitchText()) + " ", 1),
                      _createVNode(_component_svg_icon, {
                        type: "mdi",
                        path: _ctx.mdiChevronRight
                      }, null, 8, ["path"])
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.getPedigreeState === 0 && _ctx.windowWidth < _ctx.Device.MOBILEMAX) || _ctx.windowWidth > _ctx.Device.MOBILEMAX)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_PedigreeCardView, {
                "pedigree-bull": _ctx.getPedigreeBull,
                "is-sire": true,
                type: "child"
              }, null, 8, ["pedigree-bull"])
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.getPedigreeState === 1 && _ctx.windowWidth < _ctx.Device.MOBILEMAX) || _ctx.windowWidth > _ctx.Device.MOBILEMAX)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_PedigreeCardView, {
                "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'SIRE' ).identification,
                "is-sire": true,
                type: "parents",
                "type-parents": "SIRE",
                onClicked: _ctx.onClickSire
              }, null, 8, ["pedigree-bull", "onClicked"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_PedigreeCardView, {
                "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'DAM' ).identification,
                type: "parents",
                "type-parents": "DAM",
                onClicked: _ctx.onClickSire
              }, null, 8, ["pedigree-bull", "onClicked"])
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.getPedigreeState === 2 && _ctx.windowWidth < _ctx.Device.MOBILEMAX) || _ctx.windowWidth > _ctx.Device.MOBILEMAX)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRE' ).identification,
                  "is-sire": true,
                  type: "parents",
                  "type-parents": "SS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAM' ).identification,
                  type: "parents",
                  "type-parents": "SD"
                }, null, 8, ["pedigree-bull"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRE' ).identification,
                  "is-sire": true,
                  type: "parents",
                  "type-parents": "DS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAM' ).identification,
                  type: "parents",
                  "type-parents": "DD"
                }, null, 8, ["pedigree-bull"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      ((_ctx.getPedigreeState === 3 && _ctx.windowWidth < _ctx.Device.MOBILEMAX) || _ctx.windowWidth > _ctx.Device.MOBILEMAX)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRES_SIRE' ).identification,
                  "is-sire": true,
                  "type-parents": "SSS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_SIRES_DAM' ).identification,
                  "type-parents": "SSD"
                }, null, 8, ["pedigree-bull"])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAMS_SIRE' ).identification,
                  "is-sire": true,
                  "type-parents": "SDS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'PATERNAL_GRAND_DAMS_DAM' ).identification,
                  "type-parents": "SDD"
                }, null, 8, ["pedigree-bull"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRES_SIRE' ).identification,
                  "is-sire": true,
                  "type-parents": "DSS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_SIRES_DAM' ).identification,
                  "type-parents": "DSD"
                }, null, 8, ["pedigree-bull"])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAMS_SIRE' ).identification,
                  "is-sire": true,
                  "type-parents": "DDS",
                  onClicked: _ctx.onClickSire
                }, null, 8, ["pedigree-bull", "onClicked"])
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_PedigreeCardView, {
                  "pedigree-bull": _ctx.getPedigreeBull?.pedigree.find((item) => item.relation === 'MATERNAL_GRAND_DAMS_DAM' ).identification,
                  "type-parents": "DDD"
                }, null, 8, ["pedigree-bull"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}