<style lang="scss" scoped>
@import "../scss/documentation.scss";
@import "../scss/detail.scss";
</style>

<template>
    <div class="documentation">
        <v-card-title class="crv-header crv-header level-two">
            {{ $t("formulas.header")
            }}
        </v-card-title>
        <div class="documentation__formulas">
            <a v-on:click="openChapterLink" class="link-chapter" target="_blank" rel="noreferrer noopener">{{
                $t("formulas.chapters")
            }}  <svg-icon width="18" height="18" color="#2f679c" type="mdi" :path="mdiOpenInNew"></svg-icon>
            </a>
        </div>

        <v-card-title class="crv-header crv-header level-two">
            {{ $t("formulas.source.header")
            }}
        </v-card-title>
        <div class="documentation__formulas">
            <div class="documentation__container">
                <div class="documentation__listOne">
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> Nat
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.nat")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> Int
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.int")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> Mdn
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.mdn")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> OFw
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.ofw")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> GNat
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.gnat")
                        }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="documentation__container">
                <div class="documentation__listOne">
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> GFw
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.gfw")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> IGFw
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.igfw")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> Gint
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.gint")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left ">
                        <div class="documentation__listOne__columnOne"> GMdn
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.source.text.gmdn")
                        }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-card-title class="crv-header crv-header level-two">
            {{ $t("details.summary.first.inheritedFactors")
            }}
        </v-card-title>
        <div class="text-left legend mb-6">
            <div class="documentation__text">
                {{ $t('formulas.inheritedLegend.description') }}
            </div>
            <div class="documentation__text">
                {{ $t('formulas.inheritedLegend.plus') }}
            </div>
            <div class="documentation__text">
                {{ $t('formulas.inheritedLegend.minus') }}
            </div>
            <div class="documentation__text">
                {{ $t('formulas.inheritedLegend.asterix') }}
            </div>
        </div>
        

        <div class="documentation__formulas">
            <div class="documentation__container">
                <div class="documentation__listOne">
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            BD
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.bd")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> BL
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.bl")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> BR
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.br")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> BY
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.by")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> CD
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.cd")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> CV
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.cv")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> D1
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.d1")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> D2
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.d2")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> DP
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.dp")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> GG
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.gg")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne"> GT
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.gt")
                        }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="documentation__container">
                <div class="documentation__listOne">
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            LR
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.lr")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            MF
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.mf")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            MW
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.mw")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            OT
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.ot")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            PO
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.po")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            QT
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.qt")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            RF
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.rf")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            RT
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.rt")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            TV
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.tv")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            VR
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.vr")
                        }}
                        </div>
                    </div>
                    <div class="list-item text-left">
                        <div class="documentation__listOne__columnOne">
                            ZN
                        </div>
                        <div class="documentation__listOne__columnTwo"> {{
                            $t("formulas.inherited.zn")
                        }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="documentation__formulas">
            <div class="documentation__container">
                <div class="text-column">
                    <v-card-title class="crv-header crv-header level-two">
                        {{ $t("details.summary.first.kinshipRate")
                        }}
                    </v-card-title>
                    <div class="documentation__text">
                        {{ $t('formulas.kinshipRate.text-one') }}
                    </div>
                    <div class="documentation__text">
                        {{ $t('formulas.kinshipRate.text-two') }}
                    </div>
                    <div class="documentation__text">
                        {{ $t('formulas.kinshipRate.text-three') }}
                    </div>
                    <div class="documentation__text">
                        {{ $t('formulas.kinshipRate.text-four') }}
                    </div>
                    <div class="documentation__text">
                        {{ $t('formulas.kinshipRate.text-five') }}
                    </div>
                </div>

            </div>
            <div class="documentation__container">
                <div class="text-column">
                    <v-card-title class="crv-header crv-header level-two">
                        {{ $t("formulas.relative.header")
                        }}
                    </v-card-title>
                    <div class="spacing-medium documentation__text">
                        {{
                            $t("formulas.relative.text")
                        }}
                    </div>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th v-for="item in headerRows[0].cells" :key="item.id" class="text-left">
                                    {{ item.value }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in bodyRows" :key="row.id">
                                <td class="text-left" v-for="cell in  row.cells" :key="cell.id">{{ cell.value }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatNumber } from "@/scripts/format-number";
import { DocumentationHeaders } from "../models/table-headers";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiOpenInNew } from '@mdi/js';
import { formatLocale } from "../scripts/format-locale";

export default defineComponent({
    name: "DocumentationFormulasView",
    methods: {
        formatNumber(value: string) {
            return formatNumber(value)
        },
        openChapterLink() {
            window.open(this.$t('formulas.link'))
        }
    },
    components: {
        SvgIcon
    },
    data() {
        return {
            mdiOpenInNew: mdiOpenInNew,
            headerRows: [
                {
                    "id": "relative",
                    "cells": DocumentationHeaders
                }
            ],
            bodyRows: [
                {
                    "id": 1,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "<88"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 2,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "88-91"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 3,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "92-95"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 4,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "96-104"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(68.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 5,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "105-108"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 6,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "109-112"
                        },
                        {
                            "id": "freq",

                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 7,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": ">112"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                }
            ],
        };
    },
});
</script>