<style lang="scss" scoped>
@import "../scss/detail.scss";
</style>

<template>
    <div class="detail">
        <div class="detail__container">
            <div class="detail__header">
                <v-card-title class="crv-header crv-header level-one">
                    <div @click="goBackToSearch" class="detail__header__svg">
                        <chevronLeftSolid />
                    </div>
                    {{ formatEmptyToDash(bullDetail.shortName ? bullDetail.shortName : bullDetail.fullName) }}
                </v-card-title>
                <div class="detail__header-sub">{{ bullDetail.fullName ? bullDetail.fullName : '-' }} | {{
                    bullDetail.artificialInseminationNumber }} | {{ bullDetail.interbullNumber }} </div>
            </div>
            <div class="detail__select">
                <v-select :items="getHairColorOptions" density="compact" :label="$t('details.basic-calc')" bg-color="white"
                    v-model="getHairColorValue" item-title="optionText" item-value="id" return-object variant="underlined" color="primary"></v-select>
            </div>
        </div>
        <div class="detail__tab-container">
            <v-tabs v-model="tab" color="primary">
                <v-tab @click="setGeneralView()" :color="baseBlue" value="one">{{ $t('details.header-overview') }}</v-tab>
                <v-tab :color="baseBlue" value="two">{{ $t('details.header-origin') }}</v-tab>
                <v-tab  @click="setBreedingValue()" :color="baseBlue" value="three">{{ $t('details.header-breeding') }}</v-tab>
                <v-tab @click="setGenonimcView()" :color="baseBlue" value="four">{{ $t('details.header-genomics') }}</v-tab>
                <v-tab :color="baseBlue" value="five">{{ $t('header.tab-docs') }}</v-tab>
            </v-tabs>
            <v-window v-model="tab">
                <v-window-item value="one">
                    <template v-if="!loading">
                        <GeneralView />
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="two">
                    <PedigreeView @clicked="searchBullsByITB" />
                </v-window-item>
                <v-window-item value="three">
                    <template v-if="!loading">
                        <BreedingValuesView />
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="four">
                    <template v-if="!loading">
                    <GenomicsView />
                    </template>
                    <div v-else>
                        <v-progress-circular indeterminate color="#154775" model-value="20"></v-progress-circular>
                    </div>
                </v-window-item>
                <v-window-item value="five">
                    <DocumentationFormulasView />
                </v-window-item>
            </v-window>
        </div>
        <div class="detail__collapse-container">
            <v-expansion-panels variant="accordion" multiple>
                <v-expansion-panel >
                    <v-expansion-panel-title @click="setGeneralView()">{{ $t('details.header-overview') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <GeneralView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-title>{{ $t('details.header-origin') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <PedigreeView @clicked="searchBullsByITB" />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-title  @click="setBreedingValue()">{{ $t('details.header-breeding') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <BreedingValuesView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel >
                    <v-expansion-panel-title @click="setGenonimcView()">{{ $t('details.header-genomics') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <GenomicsView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-title>{{ $t('header.tab-docs') }}</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <DocumentationFormulasView />
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
  <SireFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import chevronLeftSolid from '../assets/icons/chevronLeftSolid.vue';
import GeneralView from './GeneralView.vue';
import GenomicsView from './GenomicsView.vue';
import DocumentationFormulasView from './DocumentationFormulasView.vue';
import { Device, Colors } from '../models/enums';
import PedigreeView from './PedigreeView.vue';
import BreedingValuesView from './BreedingValuesView.vue';
import { mapGetters, mapMutations, useStore } from "vuex";
import { BullService } from "../services/BullService";
import { BullInfo } from "@/models/bullinfo";
import { formatValueOrDash } from "@/scripts/format-value-or-dash";
import { createWithEmptyValues } from "@/scripts/init-bullinfo-base";
import SireFooter from '../components/SireFooter.vue'

const bullService = new BullService();

export default defineComponent({
    name: "BullDetailView",
    components: {
        chevronLeftSolid,
        GeneralView,
        PedigreeView,
        BreedingValuesView,
        GenomicsView,
        DocumentationFormulasView,
        SireFooter
    },
    data() {
        return {
            baseBlue: Colors.BASEBLUE,
            tab: 'one',
            windowWidth: 0,
            componentKey: 0,
            loading: false
        }
    },
    created() {
        this.resetScrollToTop()
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    setup() {
        const store = useStore();


        return {
            Device,
            store
        }
    },
    computed: {
        ...mapGetters({
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
        getHairColorValue: {
            get(): { optionText: string, id: string } {
                return { optionText: this.hairColorText(this.getBaseColor), id: this.getBaseColor }
            },
            async set(value) {
                this.loading = true
                const calcType = this.getCalcType;
                const baseColor = value.id;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
                this.loading = false
                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
                this.loading = false

                }

            }
        },
        getHairColorOptions(): any {
            return [
                {
                    optionText: this.$t('details.haircolor.black-fur'),
                    id: 'Zwartbont'
                },
                {
                    optionText: this.$t('details.haircolor.red-fur'),
                    id: 'Roodbont'
                },
                {
                    optionText: this.$t('details.haircolor.double-purpose'),
                    id: 'Dubbeldoel'
                },
                {
                    optionText: this.$t('details.haircolor.belgian-blue'),
                    id: 'Belgischblauw'
                }
            ]
        }
    },
    
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
            setBreedingValueBase: "setBreedingValueBase"
        }),
        resetScrollToTop(): void {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async setGenonimcView() {
            this.loading = true;

                const calcType = 'MARKER_EFFECT';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    //TODO: calling following bullService currently raises an 404 for marker-effects, are these not loaded or broken in dev?
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType))
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        async setGeneralView() {
            this.loading = true;

            const calcType = 'GENOMIC_BREEDING_VALUE';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType))
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        async setBreedingValue() {
            this.loading = true;
            const calcType = 'GENOMIC_BREEDING_VALUE';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        formatEmptyToDash(value: number | string | undefined): number | string | undefined {
            return formatValueOrDash(value);
        },
        goBackToSearch() {
            this.setBullDetail({})
            this.setLayout('search-view')
        },
        hairColorText(val: string) {
            switch (val) {
                case 'Belgischblauw':
                    return this.$t('details.haircolor.belgian-blue')
                case 'Dubbeldoel':
                    return this.$t('details.haircolor.double-purpose')
                case 'Roodbont':
                    return this.$t('details.haircolor.red-fur')
                case 'Zwartbont':
                    return this.$t('details.haircolor.black-fur')
                default:
                    return this.$t('details.haircolor.black-fur')
            }
        },
        async searchBullsByITB(value) {
            try {
                const result: BullInfo = await bullService.getForDetailView(value)
                // this function fires on the 'clicked-event' from pedigreeView. normally a concatenated string was provided. now only the interbullNumber
                // check if this still works!
                console.log('searchBullsByITB value is: ', value)
                this.setBullDetail(result);
                if (result) {
                    this.setLayout('bull-detail-view')
                    this.resetScrollToTop()
                }
            } catch (error) {
                return
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        createPrimaryKey(interbullNumber: string, color: string, type: string): string {
            const key = `${interbullNumber}-${color}-${type}`
            return key
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
</script>