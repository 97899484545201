import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "genomics-summary__container" }
const _hoisted_2 = { class: "summary__container__row" }
const _hoisted_3 = { class: "summary__container__alert" }
const _hoisted_4 = { class: "summary__container__alert__title" }
const _hoisted_5 = { class: "summary__container__alert__text" }
const _hoisted_6 = { class: "summary__container__alert__text" }
const _hoisted_7 = { class: "summary__container__alert__text" }
const _hoisted_8 = { class: "summary__container__alert__text" }
const _hoisted_9 = { class: "summary__container__alert__text" }
const _hoisted_10 = { class: "summary__container__row" }
const _hoisted_11 = { class: "custom-table-production" }
const _hoisted_12 = { class: "custom-table-general" }
const _hoisted_13 = { class: "summary__container__row" }
const _hoisted_14 = { class: "summary__container__column" }
const _hoisted_15 = { class: "custom-table-efficiency" }
const _hoisted_16 = { class: "custom-table-health" }
const _hoisted_17 = { class: "summary__container__column" }
const _hoisted_18 = { class: "custom-table-birth" }
const _hoisted_19 = { class: "custom-table" }
const _hoisted_20 = { class: "custom-table-exterior" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_CustomTableView = _resolveComponent("CustomTableView")!
  const _component_GeneralTableView = _resolveComponent("GeneralTableView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_alert, {
          type: "info",
          variant: "tonal"
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('details.genomics-alert-header')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('details.genomics-alert-text-one')), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('details.genomics-alert-text-two')), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('details.genomics-alert-text-three')), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('details.genomics-alert-text-four')), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('details.genomics-alert-text-five')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("details.production.header")), 1)
          ]),
          _: 1
        }),
        (_ctx.productionBreedingValueGroupList && _ctx.productionBreedingValueGroupList[0] && _ctx.productionBreedingValueGroupList[0].breedingValueList.length > 0)
          ? (_openBlock(), _createBlock(_component_CustomTableView, {
              key: 0,
              "body-rows": _ctx.mapCustomProductionBreedingValues(
            _ctx.productionBreedingValueGroupList[0].breedingValueList,
            _ctx.productionBreedingValueGroupList[0].tagName
          )
          ,
              "header-rows": _ctx.customColumns
            }, null, 8, ["body-rows", "header-rows"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_ctx.generalBreedingValueGroupList && _ctx.generalBreedingValueGroupList[0] && _ctx.generalBreedingValueGroupList[0].breedingValueList.length > 0)
          ? (_openBlock(), _createBlock(_component_GeneralTableView, {
              key: 0,
              "body-rows": _ctx.mapGeneralBreedingValues(
              _ctx.generalBreedingValueGroupList[0].breedingValueList,
              _ctx.generalBreedingValueGroupList[0].tagName
            )
            ,
              "header-rows": _ctx.generalColumns
            }, null, 8, ["body-rows", "header-rows"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.efficiency.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.efficiencyBreedingValueGroupList && _ctx.efficiencyBreedingValueGroupList[0] && _ctx.efficiencyBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_CustomTableView, {
                key: 0,
                "body-rows": _ctx.mapCustomBreedingValues(
              _ctx.efficiencyBreedingValueGroupList[0].breedingValueList,
              _ctx.efficiencyBreedingValueGroupList[0].tagName
            )
            ,
                "header-rows": _ctx.customColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.health.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.healthBreedingValueGroupList && _ctx.healthBreedingValueGroupList[0] && _ctx.healthBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(
              _ctx.healthBreedingValueGroupList[0].breedingValueList,
              _ctx.healthBreedingValueGroupList[0].tagName
            )
            ,
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.functional.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.functionalBreedingValueGroupList && _ctx.functionalBreedingValueGroupList[0] && _ctx.functionalBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(
              _ctx.functionalBreedingValueGroupList[0].breedingValueList,
              _ctx.functionalBreedingValueGroupList[0].tagName
            )
            ,
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.birth.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.birthBreedingValueGroupList && _ctx.birthBreedingValueGroupList[0] && _ctx.birthBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(
            _ctx.birthBreedingValueGroupList[0].breedingValueList,
            _ctx.birthBreedingValueGroupList[0].tagName
          )
          ,
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorabove.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.exteriorAboveBreedingValueGroupList && _ctx.exteriorAboveBreedingValueGroupList[0] && _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(
              _ctx.exteriorAboveBreedingValueGroupList[0].breedingValueList,
              _ctx.exteriorAboveBreedingValueGroupList[0].tagName
            )
            ,
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("details.exteriorbelow.header")), 1)
            ]),
            _: 1
          }),
          (_ctx.exteriorBelowBreedingValueGroupList && _ctx.exteriorBelowBreedingValueGroupList[0] && _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0)
            ? (_openBlock(), _createBlock(_component_GeneralTableView, {
                key: 0,
                "body-rows": _ctx.mapGeneralBreedingValues(
              _ctx.exteriorBelowBreedingValueGroupList[0].breedingValueList,
              _ctx.exteriorBelowBreedingValueGroupList[0].tagName
            )
            ,
                "header-rows": _ctx.generalColumns
              }, null, 8, ["body-rows", "header-rows"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}