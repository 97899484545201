<template>
  <SireHeader />
  <router-view></router-view>
</template>

<style lang="scss" src="./scss/_global.scss"></style>

<script lang="ts">
import { LayoutLanguages } from "./i18n/config/locales"
import { defaultLocale } from "./i18n/config"
import SireHeader from './components/SireHeader.vue'
import { defineComponent } from "vue"

export default defineComponent({
  name: "App",
  components: { SireHeader },
  data: () => ({ LayoutLanguages, defaultLocale }),
})

</script>

<style lang="scss">
//importing stylesheets does not work yet, with Vite is does?
// @import "@crv/vue-3-components/dist/assets/base.scss";
// @import "@crv/vue-3-components/dist/assets/vuetify/crv-vuetify-styling";

/* TODO force from Vue-3-Components? */
mark { 
    background-color: transparent;
    // font-weight: $font-weight-strong;
    font-weight: 700;
}
</style>