<style lang="scss" scoped>
@import '../scss/breeding.scss';
</style>

<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th class="text-center" v-for="item in headerRows[0].cells" :key="item.id">
                    <template v-if="item.slotName === 'custom-styled-column1'">
                        <div class="breeding__general__bar-header" :class="hideHeader ? 'hidden' : null">
                            <!-- the substring is for the table header row for to set the min and max value (92 96 100 104 108) -->
                            <div>
                                {{ setHeaderForHOVOBar(item.value, 0, 2) }}
                            </div>
                            <div>
                                {{ setHeaderForHOVOBar(item.value, 2, 2) }}
                            </div>
                            <div class="column-cell-container">
                                <div class="hidden">
                                    {{ setHeaderForHOVOBar(item.value, 4, 3) }}
                                </div>
                                <div class="header-cell-middle">
                                    {{ setHeaderForHOVOBar(item.value, 4, 3) }}
                                </div>
                            </div>
                            <div>
                                {{ setHeaderForHOVOBar(item.value, 7, 3) }}
                            </div>
                            <div>
                                {{ setHeaderForHOVOBar(item.value, 10, 3) }}
                            </div>
                        </div>
                    </template>
                    <span v-else>
                        {{ item.value }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td v-for="cell in  row.cells" :key="cell.id"
                    :class="cell.slotName === 'text-left' ? 'text-left' : 'text-center'">
                    <template v-if="cell.slotName === 'custom-styled-column-3'">
                        <div class="cell-container-measurement" :class="isEfficiency ? 'efficiency' : ''">
                            {{ isEfficiency ? returnNumberorString(cell.value.value, cell.value.tagName) : formatEmptyToDash(cell.value.value, true) }} {{ translateDaysMixin(cell.value.unitOfMeasurement) }}
                        </div>
                    </template>
                    <span v-if="!(cell.id === 'value' || cell.id === 'measurement')">
                        {{ showIndentationForMilkingRobot(cell.value) }}
                    </span>
                    <template v-if="cell.slotName === 'custom-styled-column'">
                        <div class="cell-container">
                            <div class="subTextLeft">{{ addDutchTranslatedSubtextLeft(row.id) }}</div>
                            <div class="hobv-bar">
                                <HorizontalBreedingValueBar :with-sub-text="withSubText"
                                    :min-value="hideHeader ? checkMinValue(row.id) : minValueGenericTable"
                                    :max-value="hideHeader ? checkMaxValue(row.id) : maxValueGenericTable"
                                    :value="cell.value" />
                            </div>
                            <div class="subTextRight">{{ addDutchTranslatedDifferentSubtextRight(row.id)
                            }}</div>
                        </div>

                    </template>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HorizontalBreedingValueBar from '@/components/HorizontalBreedingValueBar.vue';
import { formatNumber } from '@/scripts/format-number';
import { BullDetail } from '@/models/enums';
import { translateDaysMixin } from '../mixins/translate-days';
import { Locales } from '@/i18n/config/locales';
import { formatValueOrDash } from '@/scripts/format-value-or-dash';
import { formatLocale } from '@/scripts/format-locale';
import {displayDecimals} from "@/scripts/display-numbers-decimals";

export default defineComponent({
    name: 'BreedingGeneralTable',
    mixins: [translateDaysMixin],
    components: {
        HorizontalBreedingValueBar
    },
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        withSubText: {
            type: Boolean,
            required: false,
            default: false
        },
        isEfficiency: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            minValueEffiencyTable: 10,
            maxValueEffiencyTable: 160,
            maxValueGenericTable: 108,
            minValueGenericTable: 92,
        }
    },
    methods: {
      showIndentationForMilkingRobot(value: string): string {
        if (typeof value === "string") {
          if (value.includes("robot") && !value.includes("index")) {
            return '\xa0\xa0\xa0' + value;
          }

          if (value.includes("Recovery") || value.includes("Stability") || value.includes("Herstel") || value.includes("Stabiliteit")){
            return '\xa0\xa0\xa0' + value;
          }
        }

        return value;
      },
        formatNumber(value: number): string {
            return formatNumber(value?.toString())
        },
        returnNumberorString(value, tagname) {
            if (!value) {
                return '-'
            }

            if (tagname === 'FoodCostSavingMaintenance'){
              return this.$n(Number(displayDecimals(value, 2)), 'decimal', formatLocale(this.$i18n.locale));
            }

            if (tagname === 'DryMatterIntake' || tagname === 'Ureum') {
                return this.$n(Number(value), 'decimal', formatLocale(this.$i18n.locale))
            }
            else {
                return value
            }
        },
        formatEmptyToDash(value: number | string | undefined, round?: boolean): number | string | undefined {
            return formatValueOrDash(value, round);
        },
        setHeaderForHOVOBar(value: string, indexBegin: number, indexEnd: number) {
            if (!value || !indexEnd) {
                return;
            }
            return value.substr(indexBegin, indexEnd)
        },
        addDutchTranslatedSubtextLeft(tag: string): string {
            if (this.$i18n.locale === Locales.nlNl) {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagSmallSize')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagSmallWidth')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagHighPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagSmallWidth')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagHockedIn')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagFew')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagOutward')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagLoose')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagKort')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagLowHind')
                    default:
                        return ''
                }
            } else {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagShort')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagClosed')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagSkinny')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagHighPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagHockedIn')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagOutward')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagShort')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagLow')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagWeak')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagLowHind')
                    default:
                        return ''
                }
            }
        },
        addDutchTranslatedDifferentSubtextRight(tag: string): string {
            if (this.$i18n.locale === Locales.nlNl) {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagLarge')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagBroad')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagInclined')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagBroad')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagCurved')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagMany')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagParallel')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagPowerful')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagVast')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagNauw')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagLong')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagHigh')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagSterk')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagNauw')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagHigherHind')
                    default:
                        return ''
                }
            } else {
                switch (tag) {
                    case BullDetail.HEIGHT:
                        return this.$t('details.sub-tags.tagTall')
                    case BullDetail.ADVANCE:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.CONTENT:
                        return this.$t('details.sub-tags.tagDeep')
                    case BullDetail.OPEN:
                        return this.$t('details.sub-tags.tagOpen')
                    case BullDetail.CONDITIONSCORE:
                        return this.$t('details.sub-tags.tagFat')
                    case BullDetail.CROSSPOS:
                        return this.$t('details.sub-tags.tagSlopedPins')
                    case BullDetail.CROSSWIDTH:
                        return this.$t('details.sub-tags.tagWide')
                    case BullDetail.LEGPOSBACK:
                        return this.$t('details.sub-tags.tagStraight')
                    case BullDetail.LEGPOSSIDE:
                        return this.$t('details.sub-tags.tagCurved')
                    case BullDetail.CLAWANGLE:
                        return this.$t('details.sub-tags.tagSteep')
                    case BullDetail.FRONTLEGPOS:
                        return this.$t('details.sub-tags.tagParallel')
                    case BullDetail.LEGUSE:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.FRONTUDDERATTA:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.FRONTTREAT:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.TEATLENGTH:
                        return this.$t('details.sub-tags.tagLong')
                    case BullDetail.UDDERDEPTH:
                        return this.$t('details.sub-tags.tagShallow')
                    case BullDetail.BACKUDDERHEIGHT:
                        return this.$t('details.sub-tags.tagHigh')
                    case BullDetail.STIFLE:
                        return this.$t('details.sub-tags.tagStrong')
                    case BullDetail.BACKTEAT:
                        return this.$t('details.sub-tags.tagNarrow')
                    case BullDetail.UDDERBALANCE:
                        return this.$t('details.sub-tags.tagHigherHind')
                    default:
                        return ''
                }
            }
        },
        checkMinValue(id: string): number {
            switch (id) {
                case BullDetail.LIFESPAN:
                    return -516
                case BullDetail.DRYMATTER:
                    return -2.72
                case BullDetail.FOODCOSTMAIN:
                    return -1.48
                case BullDetail.FOODCOST:
                    return -90
                case BullDetail.UREUM:
                    return -4.2
                case BullDetail.BODYWEIGHT:
                    return 91
                case BullDetail.LIFEPRODINDEX:
                    return -3200
                case BullDetail.LIFEPRODMILK:
                    return -14300
                case BullDetail.LIFEPRODFAT:
                    return -550
                case BullDetail.LIFEPRODLACT:
                    return -460
                default:
                    return 92
            }
        },
        checkMaxValue(id: string): number {
            switch (id) {
                case BullDetail.LIFESPAN:
                    return 516
                case BullDetail.DRYMATTER:
                    return 2.72
                case BullDetail.FOODCOSTMAIN:
                    return 1.48
                case BullDetail.FOODCOST:
                    return 90
                case BullDetail.UREUM:
                    return 4.2
                case BullDetail.BODYWEIGHT:
                    return 109
                case BullDetail.LIFEPRODINDEX:
                    return 3200
                case BullDetail.LIFEPRODMILK:
                    return 14300
                case BullDetail.LIFEPRODFAT:
                    return 550
                case BullDetail.LIFEPRODLACT:
                    return 460
                default:
                    return 108
            }
        }
    }
});
</script>
