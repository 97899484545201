import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue'
import { refDebounced } from '@vueuse/core'


// * interface: (props and emits)

export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
    modelValue: { default: '' },
    headingText: { default: '' },
    hint: { default: '' },
    placeholder: { default: '' },
    persistentHint: { type: Boolean, default: true },
    width: { default: '100%' },
    hideDetails: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    debounceInterval: { default: 200 }
  },
  emits: ['update:model-value'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

// TODO: taken from crvSireMatch, candidate for @crv/vue-3-components
const props = __props

const emit = __emit


// * refs
const searchValue = ref('')
const debouncedSearchValue = refDebounced(searchValue, props.debounceInterval)


// * watch
watch(
    debouncedSearchValue,
    () => {
        emit('update:model-value', debouncedSearchValue.value)
    }
)


// * methods
const handleClickInput = () => {
    init()
}

const init = () => {
    // clears the input on click again, should this be configurable?
    // searchValue.value = ''
    emit('update:model-value', searchValue.value)
}

__expose({init})


return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, { width: _ctx.width }, {
    default: _withCtx(() => [
      _createVNode(_component_v_text_field, {
        modelValue: searchValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchValue).value = $event)),
        modelModifiers: { trim: true },
        autofocus: "",
        variant: "underlined",
        color: "primary",
        label: _ctx.headingText,
        placeholder: _ctx.placeholder,
        hint: _ctx.hint,
        "persistent-hint": _ctx.persistentHint,
        "hide-details": _ctx.hideDetails,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        clearable: "",
        onClick: handleClickInput
      }, null, 8, ["modelValue", "label", "placeholder", "hint", "persistent-hint", "hide-details", "disabled", "readonly"])
    ]),
    _: 1
  }, 8, ["width"]))
}
}

})