<style lang="scss" scoped>
@import '../../scss/detail.scss';
</style>

<template>
    <v-table density="compact">
    <thead>
      <tr>
        <th  v-for="item in headerRows[0].cells"
        :key="item.id" class="text-left">
          {{ item.value}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in bodyRows"
        :key="row.id"
      >
        <td  class="text-left"  v-for="cell in  row.cells"
        :key="cell.id">{{ cell.value }}</td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GeneralTableView',
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    }
});
</script>
