<template>
  <div class="print-content">
    <v-btn class="px-2" variant="text" color="primary" @click="handleClickFoo"><svg-icon type="mdi" :path="print"></svg-icon>
      {{ $t("details.print") }}
    </v-btn>
  </div>
      <div class="summary__container_desktop">
          <div class="summary__container__row">
            <div class="summary__container__block">
              <ListBlockView />
            </div>

            <div class="summary__container__block flex-column">
              <div 
                v-if="generalBreedingValueGroupList && generalBreedingValueGroupList[0] && generalBreedingValueGroupList[0].breedingValueList.length > 0"
                class="custom-table-general"
              >
                <GeneralTableView 
                  :body-rows="mapCustomBreedingValues(
                    generalBreedingValueGroupList[0].breedingValueList,
                    generalBreedingValueGroupList[0].tagName, true
                  )
                  " :header-rows="generalProdColumns" />
              </div>
            </div>
          </div>


          <div class="summary__container__row">
            <div class="custom-table-production">
              <v-card-title class="crv-header crv-header level-two">
                {{ $t("details.production.header")
                }}
              </v-card-title>
              <CustomTableView 
                v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapCustomProductionBreedingValues(
                  productionBreedingValueGroupList[0].breedingValueList,
                  productionBreedingValueGroupList[0].tagName
                )
                " :header-rows="customColumns" />
            </div>
            <div class="custom-table-birth">
              <v-card-title class="crv-header crv-header level-two">
                {{ $t("details.birth.header")
                }}
              </v-card-title>
              <BreedingGeneralTable
                v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
                :body-rows="mapGeneralBreedingValues(birthBreedingValueGroupList[0].breedingValueList, birthBreedingValueGroupList[0].tagName)"
                :header-rows="generalColumns" />
            </div>
          </div>
          <div class="summary__container__row">
            <div class="summary__container__column">
              <div class="custom-table-efficiency">
                <v-card-title class="crv-header crv-header level-two">
                  {{ $t("details.efficiency.header")
                  }}
                </v-card-title>
                <BreedingGeneralTable hideHeader
                  v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapGeneralBreedingValues(efficiencyBreedingValueGroupList[0].breedingValueList, efficiencyBreedingValueGroupList[0].tagName, true)"
                  :header-rows="generalColumns" />
              </div>
              <div class="custom-table-health">
                <v-card-title class="crv-header crv-header level-two">
                  {{ $t("details.health.header")
                  }}
                </v-card-title>
                <BreedingGeneralTable
                  v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapGeneralBreedingValues(healthBreedingValueGroupList[0].breedingValueList, healthBreedingValueGroupList[0].tagName)"
                  :header-rows="generalColumns" />
              </div>
              <div>
                <v-card-title class="crv-header crv-header level-two">
                  {{ $t("details.functional.header")
                  }}
                </v-card-title>
                <BreedingGeneralTable
                  v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapGeneralBreedingValues(functionalBreedingValueGroupList[0].breedingValueList, functionalBreedingValueGroupList[0].tagName)"
                  :header-rows="generalColumns" />
              </div>
            </div>
            <div class="summary__container__column">

              <div class="custom-table">
                <v-card-title class="crv-header crv-header level-two">
                  {{ $t("details.exteriorabove.header")
                  }}
                </v-card-title>
                <BreedingGeneralTable 
                  v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapGeneralBreedingValues(
                    exteriorAboveBreedingValueGroupList[0].breedingValueList,
                    exteriorAboveBreedingValueGroupList[0].tagName
                  )
                  " :header-rows="generalColumns" />
              </div>
              <div class="custom-table-exterior">
                <v-card-title class="crv-header crv-header level-two">
                  {{ $t("details.exteriorbelow.header")
                  }}
                </v-card-title>
                <BreedingGeneralTable 
                    v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0"
                    withSubText :body-rows="mapGeneralBreedingValues(
                    exteriorBelowBreedingValueGroupList[0].breedingValueList,
                    exteriorBelowBreedingValueGroupList[0].tagName
                  )
                  " :header-rows="generalColumns" />
              </div>
            </div>
          </div>
      </div>

  <div class="pleft-for-print" style="display: none;">
    <crv-html-to-pdf ref="foo" orientation="portrait" :name="generateFileName()" :pageNumberLabel="getPageName()">
      <div class="summary__container">
        <crv-pdf-page is-first-page :title="bullDetail.shortName ? bullDetail.shortName : '-'"
          :subtitle="formatEmptyToDash(bullDetail.fullName)"
          :identifier="formatEmptyToDash(bullDetail.artificialInseminationNumber) + ' | ' + formatEmptyToDash(bullDetail.lifeNumber)"
          :date-label="getDate()">
          <div class="summary__container__print">
            <div class="summary__container__block">
              <ListBlockView />
            </div>

            <div class="summary__container__block flex-column">
              <div class="custom-table-general">
                <GeneralTableView 
                  v-if="generalBreedingValueGroupList && generalBreedingValueGroupList[0] && generalBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapCustomBreedingValues(
                  generalBreedingValueGroupList[0].breedingValueList,
                  generalBreedingValueGroupList[0].tagName, true
                )
                  " :header-rows="generalProdColumns" />
              </div>
            </div>
          </div>

          <div class="summary__container__print">
            <div class="custom-table-production">
              <p class="header-styling text-caption font-weight-bold">
                {{ $t("details.production.header")}}
              </p>
              <CustomTableView 
                v-if="productionBreedingValueGroupList && productionBreedingValueGroupList[0] && productionBreedingValueGroupList[0].breedingValueList.length > 0"
                :body-rows="mapCustomBreedingValues(
                  productionBreedingValueGroupList[0].breedingValueList,
                  productionBreedingValueGroupList[0].tagName
                )
                " :header-rows="customColumns" />
            </div>
            <div class="custom-table-birth">
              <p class="header-styling text-caption font-weight-bold">
                {{ $t("details.birth.header")
                }}
              </p>
              <BreedingGeneralTable
                v-if="birthBreedingValueGroupList && birthBreedingValueGroupList[0] && birthBreedingValueGroupList[0].breedingValueList.length > 0"
                :body-rows="mapGeneralBreedingValues(birthBreedingValueGroupList[0].breedingValueList, birthBreedingValueGroupList[0].tagName)"
                :header-rows="generalColumns" />
            </div>

          </div>
          <div class="summary__container__print">
            <div class="summary__container__column">
              <div class="custom-table-efficiency">
                <p class="header-styling text-caption font-weight-bold">
                  {{ $t("details.efficiency.header")
                  }}
                </p>
                <BreedingGeneralTable hideHeader
                    v-if="efficiencyBreedingValueGroupList && efficiencyBreedingValueGroupList[0] && efficiencyBreedingValueGroupList[0].breedingValueList.length > 0"
                    :body-rows="mapGeneralBreedingValues(efficiencyBreedingValueGroupList[0].breedingValueList, efficiencyBreedingValueGroupList[0].tagName, true)"
                  :header-rows="generalColumns" />
              </div>
              <div class="custom-table-health">
                <p class="header-styling text-caption font-weight-bold">
                  {{ $t("details.health.header")
                  }}
                </p>
                <BreedingGeneralTable
                    v-if="healthBreedingValueGroupList && healthBreedingValueGroupList[0] && healthBreedingValueGroupList[0].breedingValueList.length > 0"
                    :body-rows="mapGeneralBreedingValues(healthBreedingValueGroupList[0].breedingValueList, healthBreedingValueGroupList[0].tagName)"
                    :header-rows="generalColumns" />
              </div>
              <div>
                <p class="header-styling text-caption font-weight-bold">
                  {{ $t("details.functional.header")
                  }}
                </p>
                <BreedingGeneralTable
                    v-if="functionalBreedingValueGroupList && functionalBreedingValueGroupList[0] && functionalBreedingValueGroupList[0].breedingValueList.length > 0"
                    :body-rows="mapGeneralBreedingValues(functionalBreedingValueGroupList[0].breedingValueList, functionalBreedingValueGroupList[0].tagName)"
                    :header-rows="generalColumns" />
              </div>
            </div>
            <div class="summary__container__column">

              <div class="custom-table">
                <p class="header-styling text-caption font-weight-bold">
                  {{ $t("details.exteriorabove.header")
                  }}
                </p>
                <BreedingGeneralTable 
                  v-if="exteriorAboveBreedingValueGroupList && exteriorAboveBreedingValueGroupList[0] && exteriorAboveBreedingValueGroupList[0].breedingValueList.length > 0"
                  :body-rows="mapGeneralBreedingValues(
                    exteriorAboveBreedingValueGroupList[0].breedingValueList,
                    exteriorAboveBreedingValueGroupList[0].tagName
                  )
                  " :header-rows="generalColumns" />
              </div>
              <div class="custom-table-exterior">
                <p class="header-styling text-caption font-weight-bold">
                  {{ $t("details.exteriorbelow.header")
                  }}
                </p>
                <BreedingGeneralTable 
                  v-if="exteriorBelowBreedingValueGroupList && exteriorBelowBreedingValueGroupList[0] && exteriorBelowBreedingValueGroupList[0].breedingValueList.length > 0"
                    withSubText :body-rows="mapGeneralBreedingValues(
                    exteriorBelowBreedingValueGroupList[0].breedingValueList,
                    exteriorBelowBreedingValueGroupList[0].tagName
                  )
                  " :header-rows="generalColumns" />
              </div>
            </div>
          </div>
        </crv-pdf-page>
      </div>

    </crv-html-to-pdf>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';
import { BreedingValue, BreedingValueSubValue } from '../models/bullinfo';
import ListBlockView from './BlockView/ListBlockView.vue';
import GeneralTableView from './BlockView/GeneralTableView.vue';
import CustomTableView from './BlockView/CustomTableView.vue';
import { TableName, HeaderValues } from '../models/enums';
import { BreedingValueGroup } from '../models/bullinfo';
import CrvHtmlToPdf from '../components/html-to-pdf/crv-html-to-pdf.vue'
import CrvPdfPage from '../components/html-to-pdf/crv-pdf-page.vue'
import { mdiPrinter } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';
import { GeneralColumns, CustomColumns, GeneralProdColumns } from "../models/table-headers";
import BreedingGeneralTable from '@/components/BreedingGeneralTable.vue';
import { translateDaysMixin } from '../mixins/translate-days';
import { formatDateMixin } from '../mixins/format-date';
import { formatLocale } from '@/scripts/format-locale';
import {displayDecimals} from "@/scripts/display-numbers-decimals";

export default defineComponent({
  name: 'GeneralView',
  mixins: [translateDaysMixin, formatDateMixin],
  data() {
    return {
      print: mdiPrinter,
      generalColumns: [
        {
          id: 'general-column',
          cells: GeneralColumns
        },
      ],
      customColumns: [
        {
          id: 'production-column',
          cells: CustomColumns
        },
      ],
      generalProdColumns: [
        {
          id: 'general-production-column',
          cells: GeneralProdColumns
        },
      ],
    };
  },
  setup() {
    const store = useStore();

    const generalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.GENERAL);
      });
    const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.HEALTH);
      }); 
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EFFICIENCY);
      });
    const productionBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.PRODUCTION);
      });

    return {
      generalBreedingValueGroupList,
      healthBreedingValueGroupList,
      functionalBreedingValueGroupList,
      birthBreedingValueGroupList,
      exteriorAboveBreedingValueGroupList,
      efficiencyBreedingValueGroupList,
      productionBreedingValueGroupList,
      exteriorBelowBreedingValueGroupList
    };
  },
  components: {
    ListBlockView,
    GeneralTableView,
    CustomTableView,
    CrvHtmlToPdf,
    CrvPdfPage,
    SvgIcon,
    BreedingGeneralTable,
  },
  computed: {
    ...mapGetters({
      bullDetail: 'getBullDetail',
    }),
  },
  methods: {
    getDate(): any {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) (dd as any) = '0' + dd;
      if (mm < 10) ( mm as any) = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    },
    getPageName(): string {
      if (this.$i18n.locale === 'en_us') {
        return 'page'
      } else {
        return 'pagina'
      }
    },
    generateFileName(): string {
      return `${this.formatEmptyToDash(this.bullDetail.shortName ? this.bullDetail.shortName : this.bullDetail.fullName)}/${this.bullDetail.artificialInseminationNumber}/${this.bullDetail.interbullNumber}`
    },

    // TODO What's the deal with below's inline Style... fix it
    async handleClickFoo(): Promise<any> {
      const heightPdf = document.querySelector('.pdf-print .pdf-page');
      const barHeader = document.querySelectorAll('.pdf-print .breeding__general__bar-header');
      const td = document.querySelectorAll('.pdf-print td');
      const listItem = document.querySelectorAll('.pdf-print .list-item');
      const headerItem = document.querySelectorAll('.pdf-print th');
      const bv = document.querySelectorAll('.pdf-print .bv-bar span');
      const hobv = document.querySelectorAll('.pdf-print .hobv-bar');
      const tagValueLow = document.querySelectorAll('.pdf-print .subTextLeft');
      const tagValueHigh = document.querySelectorAll('.pdf-print .subTextRight');
      const triangleLeft = document.querySelectorAll('.pdf-print .triangle-left');
      const triangleRight = document.querySelectorAll('.pdf-print .triangle-right');
      const header = document.querySelectorAll('.pdf-print .crv-header');
      const listHeader = document.querySelectorAll('.pdf-print .summary__container__listOne__columnOne');
      const listHeader2 = document.querySelectorAll('.pdf-print .summary__container__listOne__columnTwo');
      
      const thOne = document.querySelectorAll('.pdf-print .summary__container .v-table th:nth-child(1)');

      (heightPdf as HTMLElement).style.height = '1086px';
      (barHeader as any).forEach((el) => {
        el.style.visibility = 'hidden'
      });
      (listHeader as any).forEach((el) => {
        el.style.width = '160px'
      });
      (listHeader2 as any).forEach((el) => {
        el.style.width = '100%'
      });
      (header as any).forEach((el) => {
        el.style.fontSize = '14px'
      });
      (td as any).forEach((el) => {
        el.style.height = '14px'
      });
      (listItem as any).forEach((el) => {
        el.style.height = '14px'
        el.style.minHeight = '14px'
      });
      (headerItem as any).forEach((el) => {
        el.style.height = '14px'
        el.style.minHeight = '14px'
      });    
      (bv as any).forEach((el) => {
        el.style.height = '6px'
      });
      (hobv as any).forEach((el) => {
        el.style.marginTop = '16px'
      });
      (tagValueLow as any).forEach((el) => {
        el.style.display = 'none'
      });
      (tagValueHigh as any).forEach((el) => {
        el.style.display = 'none'
      });
      (triangleLeft as any).forEach((el) => {
        el.style.display = 'none'
      });
      (triangleRight as any).forEach((el) => {
        el.style.display = 'none'
      });
      (thOne as any).forEach((el) => {
        el.style.width = '45%'
      })
      await (this.$refs as any).foo.generatePdf();
    },

    formatEmptyToDash(value: number | string | undefined, round?: boolean): number | string | undefined {
      if (value === null || value === undefined || value === '') {
        return '-'
      }
      if (round) {
        return Math.round(value as number)
      }
      return value
    },


    mapCustomBreedingValues(list: BreedingValue[], tagName: string, isGeneral?: boolean) {
      return list
        // Breaks the app since showOnDetailView is not always present.
        // Not sure if this still is needed
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`)
            },
            {
              id: 'value',
              value: isGeneral ? this.formatEmptyToDash(value.value, true) : this.formatEmptyToDash(value.value),
            },
            {
              id: '%btbh',
              value: this.formatEmptyToDash(value.reliabilityPercentage),
            },
          ],
        }));
    },
    mapCustomProductionBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`)
            },
            {
              id: 'value',
              value: value.tagName == "FatPercentage" || value.tagName == "ProteinPercentage" || value.tagName == "LactosePercentage" ?
          displayDecimals(value?.value == null ? "" : value.value.toString(), 2, this.$i18n.locale) : value.value
            },
            {
              id: '%btbh',
              value: value.reliabilityPercentage,
            },
          ],
        }));
    },
    returnNumberorString(value, tagname) {
            if (!value) {
                return '-'
            }

            if (tagname === 'FoodCostSavingMaintenance'){
              return this.$n(Number(displayDecimals(value, 2)), 'decimal', formatLocale(this.$i18n.locale));
            }

            if (tagname === 'DryMatterIntake' || tagname === 'Ureum') {
                return this.$n(Number(value), 'decimal', formatLocale(this.$i18n.locale))
            }
            else {
                return value
            }
        },
    mapGeneralBreedingValues(list: BreedingValue[], tagName: string, isEfficiency?: boolean) {
      const breedingValueArray: BreedingValue[] = [];
      for (let index = 0; index < list.length; index++) {
        const element: BreedingValue = list[index];
        breedingValueArray.push(element);

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          // eslint-disable-next-line
          const subValueList = element.breedingValueSubValueList;
          if (subValueList === undefined || subValueList.length === 0) {
            return
          }
          else {
            const birthEase = subValueList.filter(
              (subvalue: BreedingValueSubValue) =>
                subvalue.tagName === "BirthEase"
            )[0];
            // birthEase.showOnDetailView = true;
            breedingValueArray.push(birthEase as BreedingValue);
          }
        }

        if (element.tagName.includes("robot")) {
          for (let i = 0 ; element.breedingValueSubValueList!.length > i; i++){
            breedingValueArray.push(element.breedingValueSubValueList![i]);
          }
        }

        if (process.env.VUE_APP_HIDE_VEERKRACHT === "true") {
          if (element.tagName.includes("Resilience" || element.tagName.includes("Recovery") || element.tagName.includes("Stability"))) {
            list.pop();
          }
        } else{
          if (element.tagName.includes("Resilience")) {
            for (let j = 0 ; element.breedingValueSubValueList!.length > j; j++){
              breedingValueArray.push(element.breedingValueSubValueList![j]);
            }
          }
        }
      }
      return breedingValueArray
        // .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          subTextLeft: value.tagValueLow ? this.$t(`details.sub-tags.${value.tagValueLow}`) : null,
          subTextRight: value.tagValueLow ? this.$t(`details.sub-tags.${value.tagValueHigh}`) : null,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
              slotName: 'text-left'
            },
            {
              id: HeaderValues.VALUE,
              value: this.formatEmptyToDash(value.value),
              slotName: 'custom-styled-column',
            },
            {
              id: HeaderValues.FW,
              value: `${isEfficiency ? this.returnNumberorString(value.value, value.tagName) : this.formatEmptyToDash(value.value, true)} ${this.translateDaysMixin(value.unitOfMeasurement ? value.unitOfMeasurement : '')}`,           
             }
          ]
        }));
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../scss/detail.scss";

.header-styling {
  color: $base-gray-90;
  margin: 4px !important;
}
</style>
