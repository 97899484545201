<template>
    <div class="header">
        <div class="header__container">
            <v-select :menu-props="{theme: 'light', id: 'headeraaa'}" class="header__select" v-model="$i18n.locale" item-value="param" :item-title="(item)=>`${item.item} (${item.title})`"
                :items="LayoutLanguages" variant="underlined">
                <template v-slot:selection="{ item }">
                    <span>{{ item.raw.title }}</span>
                </template>
            </v-select>
        </div>
    </div>
    <div class="tab" v-if="getLayout !== 'maintenance-view'">
        <div class="tab__container">
            <div class="tab__logo">
                <div class="tab__logo__content">
                    <div class="tab__logo__chevron">
                        <div v-if="getLayout === 'bull-detail-view'" @click="goBackToSearch">
                            <chevronLeftSolid />
                        </div>
                    </div>
                    <div style="cursor: pointer" @click="goBackToSearch()">
                        <crvLogo />
                    </div>
                    <div v-if="getLayout === 'translation-view'" class="tab__logo__hamburger" @click="goToOtherView">
                        <svg-icon type="mdi" :path="mdiClose"></svg-icon>
                    </div>
                    <div v-else class="tab__logo__hamburger" @click="goToTranslationView">
                        <svg-icon type="mdi" :path="mdiMenu"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LayoutLanguages } from "../i18n/config/locales";
import { defaultLocale } from "../i18n/config";
import crvLogo from '../assets/logo/crvLogo.vue';
import chevronLeftSolid from '../assets/icons/chevronLeftSolid.vue';
import { mapGetters, mapMutations } from "vuex";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose, mdiMenu } from '@mdi/js';
import {BullService} from "@/services/BullService";

export default defineComponent({
    name: "SireHeader",
    components: { crvLogo, chevronLeftSolid, SvgIcon },
    data: () => ({
        mdiClose: mdiClose,
        mdiMenu: mdiMenu,
        LayoutLanguages, defaultLocale,
    }),
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setLayout: "setLayout",
            setPreviousLayout: "setPreviousLayout",
        }),
        goBackToSearch() {
            this.setBullDetail({})
            this.setLayout('search-view')
        },
        goToTranslationView() {
            this.setPreviousLayout(this.getLayout)
            this.setLayout('translation-view')
        },
        goToOtherView() {
            this.setLayout(this.getPreviousLayout)
        },
        async showMaintenanceView(){
          if (await new BullService().isUnderMaintenance()) {
            this.setLayout('maintenance-view')
          }
        }
    },
    computed: {
        ...mapGetters({
            getLayout: "getLayout",
            getSearchText: "getSearchText",
            getPreviousLayout: "getPreviousLayout",
        }),
    },
    mounted() {
        this.showMaintenanceView()
        //TODO: routing and such should not be inside this component.
    }
});
</script>

<style lang="scss" scoped>
@import '../scss/header.scss';
</style>