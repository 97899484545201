<template>
  <div style="height:70vh">
    <div>
      <!-- TODO replace with crv-fallback-content -->
      <tools-image class="tools-image mb-6"/>
    </div>
    <div class="text-container">
      <h3 class="mb-3">{{$t("maintenance-page.title")}}</h3>
      <p>
        {{$t("maintenance-page.maintenance")}}
      </p>
      <br>
      <p>
        {{$t("maintenance-page.sorry")}}
      </p>
      <br>
    </div>
  </div>
  <SireFooter />
</template>

<script lang="ts">
import {defineComponent} from "vue"
import toolsImage from '../assets/svg/toolsImage.vue'
import SireFooter from '../components/SireFooter.vue'

export default defineComponent({
  name: "MaintenanceView",

  components: {
    toolsImage,
    SireFooter
    }
})
</script>

<style lang="scss" scoped>
.tools-image {
  max-width: 216px
}

.text-container {
  max-width: 500px;
  margin: auto;
  color: $base-gray-90;
}
</style>