<style lang="scss" scoped>
@import '../scss/breeding.scss';
</style>

<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="(item, index) in headerRows[0].cells" :key="item.id" >
                    <template v-if="item.slotName === 'custom-styled-column1'">
                        <div class="header-centre">
                            <div>
                                {{ item.value }}
                            </div>
                            <div class="header-sub-text">
                              <p v-if="bodyRows[0].cells[index].sourceCompany">
                                ({{ bodyRows[0].cells[index].sourceCompany ? $t('details.source.'+ bodyRows[0].cells[index].sourceCompany) : ""}})
                              </p>
                            </div>
                        </div>
                    </template>
                    <span v-else>
                        {{ item.value }}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in bodyRows" :key="row.id">
                <td v-for="cell in  row.cells" :key="cell.id">{{ cell.value }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr v-for="row in footerRows" :key="row.id">
                <td v-for="cell in  row.cells" :key="cell.id">{{ cell.value }}</td>
            </tr>
        </tfoot>
    </v-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProductionTable',
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        },
        footerRows: {
            type: Array
        }
    },
});
</script>
